import { Str } from "core";
import {
    BasicChip,
    ButtonSize,
    Icon,
    IconButton,
    TextButton,
    Tooltip,
    TooltipPlacement,
} from "design-system";
import * as RedactionStamp from "Everlaw/Review/RedactionStamp";
import { ReactNode } from "react";
import * as React from "react";

const MAX_STAMP_CHIPS_DISPLAYED = 15;

interface RedactionStampChipProps {
    stamp: RedactionStamp;
    onClickX: (stamp: RedactionStamp) => void;
    disabled: boolean;
}

function RedactionStampChip({ stamp, onClickX, disabled }: RedactionStampChipProps): ReactNode {
    return (
        <BasicChip
            disabled={disabled}
            rightButton={
                <IconButton
                    aria-label={"Remove stamp"}
                    onClick={() => {
                        onClickX(stamp);
                    }}
                >
                    <Icon.X></Icon.X>
                </IconButton>
            }
            tooltip={
                disabled ? (
                    <Tooltip placement={[TooltipPlacement.BOTTOM, TooltipPlacement.RIGHT]}>
                        You do not have permission to remove stamps from this redaction
                    </Tooltip>
                ) : undefined
            }
        >
            {stamp.display()}
        </BasicChip>
    );
}

export interface RedactionStampChipsProps {
    stamps: RedactionStamp[];
    onClickX: (stamp: RedactionStamp) => void;
    disabled: boolean;
    onClickViewMore: () => void;
}

export function RedactionStampChips({
    stamps,
    onClickX,
    disabled,
    onClickViewMore,
}: RedactionStampChipsProps): ReactNode {
    const numHiddenStamps = stamps.length - MAX_STAMP_CHIPS_DISPLAYED;
    return (
        <>
            {stamps.length > 1 && (
                <div className={"redaction-stamper__chips-wrapper"}>
                    {stamps.slice(0, MAX_STAMP_CHIPS_DISPLAYED).map((stamp) => (
                        <RedactionStampChip
                            key={stamp.id}
                            stamp={stamp}
                            onClickX={onClickX}
                            disabled={disabled}
                        ></RedactionStampChip>
                    ))}
                    {numHiddenStamps > 0 && (
                        <TextButton onClick={onClickViewMore} size={ButtonSize.SMALL}>
                            View {numHiddenStamps} more selected{" "}
                            {Str.pluralForm("stamp", numHiddenStamps)}
                        </TextButton>
                    )}
                </div>
            )}
        </>
    );
}
