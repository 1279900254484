import { Link, Span } from "design-system";
import { checkPermissions } from "Everlaw/HeaderPermissionsUtil";
import { Recommendation } from "Everlaw/SmartOnboarding/Recommendation";
import { RecommendationLevel } from "Everlaw/SmartOnboarding/RecommendationLevel";
import { RecommendationNavigationPage } from "Everlaw/SmartOnboarding/RecommendationNavigationPage";
import { RecommendationPreferencePage } from "Everlaw/SmartOnboarding/RecommendationPreferencePage";
import { hasVisibleChrons } from "Everlaw/SmartOnboarding/RecommendationUtil";
import * as React from "react";

/**
 * Use when you want a {@link Recommendation}'s {@link Recommendation#supportedPages} to include
 * every page in {@link RecommendationNavigationPage} except for the pages passed into `toExclude`.
 */
function excludes(toExclude: RecommendationNavigationPage[]): RecommendationNavigationPage[] {
    const toExcludeSet = new Set(toExclude);
    return Object.values(RecommendationNavigationPage).filter((page) => !toExcludeSet.has(page));
}

/**
 * Static information about {@link Recommendation recommendations}. Constant names should be kept
 * consistent with Recommendation.java.
 *
 * The following recommendations have been cut from the initial release of this feature and
 * are currently never triggered. This is expected as they will eventually be refined and turned
 * back on for future releases.
 *    - ADD_STORYBUILDER_TAB
 *    - EXHIBIT_LIST_TUTORIAL
 *    - PEOPLE_PROFILES
 *    - POST_CREATE_NEW_ASSIGNMENT_GROUP
 *    - RECOMMEND_TO_VIEW_NEW_USERS
 *    - SEARCH_BY_PROJECT
 *    - SHARE_BINDER
 *    - SHARE_PRODUCTION
 *    - TRANSFER_WORK_PRODUCT
 *    - VIEW_NEW_CODES
 *    - VIEW_NEW_DOCS
 */
export const Recommendations = {
    ACTIVATE_DEDUPLICATION: new Recommendation({
        displayName: "Efficient Review: Deduplicate searches",
        messages: [
            React.createElement(
                "span",
                null,
                "Deduplicate search results to save time by reviewing only one copy "
                    + "of each document. Select ",
                React.createElement(Span.Semibold, null, "Settings"),
                " to view deduplication options.",
            ),
            React.createElement(
                "span",
                null,
                "Choose a deduplication setting from ",
                React.createElement(Span.Semibold, null, "Deduplicate among search hits"),
            ),
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
    }),
    ADD_CODES_CATEGORIES: new Recommendation({
        displayName: "Case Setup: Configure categories and codes",
        messages: [
            "Prepare for document review by configuring your project's categories and codes "
                + "early. Apply codes to tag and organize your documents.",
            "View, add, edit, and delete categories and codes from the Codes tab",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canProjectAdmin,
        isDefaultRecommendation: true,
    }),
    ADD_DOC_TO_STORYBUILDER: new Recommendation({
        displayName: "Efficient Review: Add documents to your Story",
        messages: [
            "Adding important documents to your Story during review gives your team a head "
                + "start on building a case narrative. Click on the Story button to add "
                + "this document to your Timeline.",
            "Click on this button to add the document to your story",
            "Click next to navigate to the Storybuilder Page and see the new document",
            "Start the tutorial to learn more about leveraging Storybuilder throughout your case",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        navigationPage: RecommendationNavigationPage.REVIEW,
        supportedPages: [RecommendationNavigationPage.REVIEW],
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    ADD_NOTE: new Recommendation({
        displayName: "Efficient Review: Add a document note",
        messages: [
            "Add notes to documents to annotate or highlight relevant information",
            "To add notes to documents, you’ll need to edit your review window and add the annotations tab",
            "Select the annotations tab",
            "Click here to finish editing",
            "Click here to view the annotations tab",
            "You can add a note to a document in the annotations tab",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
    }),
    ADD_STORYBUILDER_TAB: new Recommendation({
        displayName: "Add Storybuilder tab",
        messages: [
            "Edit your layout to add the Storybuilder Panel to your review window layout",
            "Select or drag this tab to a group, or create a new group",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.REVIEW,
        supportedPages: [RecommendationNavigationPage.REVIEW],
    }),
    ADD_TESTIMONY: new Recommendation({
        displayName: "Storybuilder: Create Deposition Testimony",
        messages: [
            React.createElement(
                "span",
                null,
                "Now that a transcript has been added to your deposition, you can elevate key "
                    + "quotes as testimony. These quotes can be organized in your Timeline and cited "
                    + "in future Depositions. Select ",
                React.createElement(Span.Semibold, null, "Start tutorial"),
                " to learn more.",
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.DEPOSITION,
        supportedPages: [RecommendationNavigationPage.DEPOSITION],
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    ADD_TRANSCRIPT_RENUMBERING: new Recommendation({
        displayName: "Transcripts: Renumber pages",
        messages: [
            React.createElement(
                "span",
                null,
                "Make sure transcript page numbering is correct for accurate transcript navigation and citations.",
                React.createElement("br"),
                React.createElement("br"),
                "Renumber transcript pages if needed.",
            ),
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.DEPOSITION,
        supportedPages: [RecommendationNavigationPage.DEPOSITION],
        hasPermission: (props) => props.userPerms.canReceiveSB,
    }),
    ADD_USERS_TO_ASSIGNMENT: new Recommendation({
        displayName: "Manage Review: Assign out documents for review",
        messages: [
            React.createElement(
                "span",
                null,
                "Assignment groups facilitate efficient document review. Create an assignment group "
                    + "to organize review workflows, give instructions to your reviewers, and track "
                    + "progress overall. Select ",
                React.createElement(Span.Semibold, null, "Start tutorial"),
                " to learn more.",
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.AssignmentGroups,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: [RecommendationNavigationPage.HOME],
        hasPermission: (props) => props.userPerms.canCreateAGs,
    }),
    BATCH_MODIFY: new Recommendation({
        displayName: "Efficient Review: Batch modify documents",
        messages: [
            React.createElement(
                "span",
                null,
                "There are some patterns in your review work. Review multiple documents at once "
                    + "using batch modification. Select any subset of documents from this results table "
                    + "and select ",
                React.createElement(Span.Semibold, null, "Batch"),
                ".",
            ),
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Modify"),
            ),
            "Here, you can add or remove codes, notes, ratings, binders, notes, and more. "
                + "All changes will be applied to all selected documents.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
        isDefaultRecommendation: true,
        hasPermission: (props) => props.userPerms.canBatchUpdate,
    }),
    CHECK_OUT_ASSIGNMENT: new Recommendation({
        displayName: "Review assignment",
        messages: [
            "You've been assigned documents to review! This card contains additional "
                + "information about your assignment, such as the review criteria.",
            "This results table lists your assigned documents and highlights key metadata. "
                + "Select a row to open the review window and apply review work.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.AssignmentGroups,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: [RecommendationNavigationPage.HOME],
        isDefaultRecommendation: true,
    }),
    CHECK_OUT_PC: new Recommendation({
        displayName: "Manage Review: Create a predictive coding model",
        messages: [
            "Congratulations on finding 50 hot or warm documents in your project. "
                + "To speed this process up, you can create a predictive coding model by clicking here.",
            "Now select Predictive Coding",
            "Click here to start a tutorial on setting up a Predictive Coding model",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.PredictiveCoding,
        navigationPage: RecommendationNavigationPage.PREDICTIVE_CODING_WIZARD,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canCreatePC,
        isDefaultRecommendation: true,
    }),
    CREATE_FOLDER: new Recommendation({
        displayName: "Collaboration: Create homepage folders",
        messages: [
            React.createElement(
                "span",
                null,
                "Stay organized when collaborating with your team by creating "
                    + "folders. Homepage folders allow you to organize cards, either for yourself "
                    + "or as part of a shared workspace with others in the project. Select ",
                React.createElement(Span.Semibold, null, "Next"),
                " to create a folder.",
            ),
            React.createElement(
                "span",
                null,
                "Select the ",
                React.createElement(Span.Semibold, null, "Homepage"),
                " button",
            ),
            "Select the plus (+) sign to create a new folder",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        isDefaultRecommendation: true,
    }),
    CREATE_GROUPS_ADD_USERS: new Recommendation({
        displayName: "Case Setup: Create user groups",
        messages: [
            "Before adding users to your project, create user groups to manage their "
                + "permissions. Select the Project Management button.",
            React.createElement(
                "span",
                null,
                "Now select ",
                React.createElement(Span.Semibold, null, "Project Settings"),
            ),
            React.createElement(
                "span",
                null,
                "Templates help you quickly create a user group for common roles. You "
                    + "can further customize the permissions from the ",
                React.createElement(Span.Semibold, null, "Permissions"),
                " tab. Select ",
                React.createElement(Span.Semibold, null, "New group"),
                " when you're done.",
            ),
            React.createElement(
                "span",
                null,
                "When you've finished defining your new user group, select ",
                React.createElement(Span.Semibold, null, "Create"),
            ),
            React.createElement(
                "span",
                null,
                "When you're ready to add users to your project, select the ",
                React.createElement(Span.Semibold, null, "Users"),
                " tab",
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canProjectAdmin,
        isDefaultRecommendation: true,
    }),
    CREATE_PERSISTENT_HIGHLIGHTS: new Recommendation({
        displayName: "Case Setup: Configure persistent highlights",
        messages: [
            "Persistent Hits are content highlights that appear for all users in the project. "
                + "Create them to draw attention to important key words that inform review decisions, "
                + "like search terms or PII.",
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Project Settings"),
            ),
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "General"),
            ),
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Persistent Highlights"),
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: excludes([
            RecommendationNavigationPage.REVIEW,
            RecommendationNavigationPage.SETTINGS,
        ]),
        hasPermission: (props) => props.userPerms.canProjectAdmin,
        isDefaultRecommendation: true,
    }),
    CREATE_PRODUCTION_PROTOCOL: new Recommendation({
        displayName: "Case Setup: Create a production protocol",
        messages: [
            React.createElement(
                "span",
                null,
                "Congratulations on your first native data upload! If your "
                    + "project has codes, a great next step is to create a production protocol "
                    + "from a template. Early set up supports smooth production QA and quicker "
                    + "turn around times. Select ",
                React.createElement(Span.Semibold, null, "Next"),
                " to access Productions.",
            ),
            "Now select Production Protocols",
            "Click here to start a tutorial on creating a Production protocol",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Productions,
        supportedPages: [RecommendationNavigationPage.DATA],
        hasPermission: (props) => props.userPerms.canShareProductions,
        isDefaultRecommendation: true,
    }),
    CREATE_REDACTION_STAMPS: new Recommendation({
        displayName: "Case Setup: Create redaction stamps",
        messages: [
            React.createElement(
                "span",
                null,
                "A user in your project has started redacting. Provide your reviewers "
                    + "with pre-set redaction stamps to standardize the text displayed on "
                    + "redactions. Select the ",
                React.createElement(Span.Semibold, null, "Project Management"),
                " button to get started.",
            ),
            "Click here to access Project Settings",
            "Redaction stamp tools are located in the Production Tools tab",
            "Enter your Redaction Stamps here",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    CREATE_STR: new Recommendation({
        displayName: "Data Exploration: Create a Search Term Report",
        messages: [
            "Create a Search Term Report (STR) to view the results of multiple content and/or "
                + "metadata searches simultaneously. Use STRs for early case assessment and to "
                + "prioritize document review.",
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Search Term Reports"),
            ),
            "To learn how to set up a Search Term Report, complete the tutorial",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.SearchTermReports,
        navigationPage: RecommendationNavigationPage.SEARCH_TERM_REPORT_CREATE,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canCreateSTRs,
    }),
    CUSTOMIZE_REVIEW_LAYOUT: new Recommendation({
        displayName: "Efficient Review: Customize review window layouts",
        messages: [
            "Customize your review window layout to organize your most commonly used tools and hide the rest",
            React.createElement(
                "span",
                null,
                "Add and remove tools, edit a tab's content, or rearrange your tabs. When ready, select ",
                React.createElement(Span.Semibold, null, "Save"),
                ".",
            ),
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
        isDefaultRecommendation: true,
    }),
    DEFAULT_REVIEW_LAYOUT: new Recommendation({
        displayName: "Manage Review: Create default review layouts",
        messages: [
            React.createElement(
                "span",
                null,
                "Share your optimized view with your reviewers. Select ",
                React.createElement(Span.Semibold, null, "Save"),
                " to choose the default layout.",
            ),
            "Select an existing layout or create a new one",
            React.createElement(
                "span",
                null,
                "Check ",
                React.createElement(Span.Semibold, null, "Make this layout the project default"),
            ),
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Save"),
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
    }),
    DISABLE_RECOMMENDATIONS: new Recommendation({
        displayName: "Disable recommendations",
        messages: [
            "You can turn off smart recommendations at any time by going to recommendation "
                + "settings here",
        ],
        pagePref: RecommendationPreferencePage.Homepage,
        recLevel: RecommendationLevel.USER_LEVEL,
        supportedPages: excludes([]),
    }),
    DO_PRODUCTION_MODIFICATION: new Recommendation({
        displayName: "Modify productions",
        messages: [
            React.createElement(
                "span",
                null,
                "Would you prefer to edit this production instead of deleting it? Select ",
                React.createElement(Span.Semibold, null, "Next"),
                " to access your modification options.",
            ),
            "Select the 3-dot menu to access production modification tools",
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Modify Production"),
            ),
            React.createElement(
                "span",
                null,
                "From here, you can clawback, unprivilege, or reproduce documents with a "
                    + "new configuration. ",
                React.createElement(Link, {
                    href: "https://support.everlaw.com/hc/en-us/articles/115001517212-Production-Modification-Tools",
                    children: "Learn more.",
                }),
            ),
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Productions,
        supportedPages: [RecommendationNavigationPage.PRODUCTIONS],
        hasPermission: (props) => props.userPerms.canAdminProductions,
        isDefaultRecommendation: true,
    }),
    EXHIBIT_LIST_TUTORIAL: new Recommendation({
        displayName: "Exhibit list tutorial",
        messages: [
            "Now that you've scheduled a deposition, you may want to learn about exhibit lists on Everlaw. "
                + "Click here to walk through a tutorial about exhibit lists.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.DEPOSITION,
        supportedPages: [RecommendationNavigationPage.DEPOSITION],
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
        sbbeAccessible: true,
    }),
    OPEN_DATA_VISUALIZER: new Recommendation({
        displayName: "Data Exploration: Visualize your search results",
        messages: [
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Visualize"),
                " to explore the characteristics of search results at a "
                    + "glance and filter by particular attributes. The visualizer is "
                    + "particularly useful for early data exploration or iterative search building.",
            ),
            React.createElement(
                "span",
                null,
                "If you are new to Everlaw's Data Visualizer, select ",
                React.createElement(Span.Semibold, null, "Start tutorial"),
                " to learn more",
            ),
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
    }),
    PEOPLE_PROFILES: new Recommendation({
        displayName: "Add People Profiles",
        messages: [
            "Now that you’ve added documents to your Story, "
                + "you may want to add People profiles to your Story. Click here to open the Storybuilder menu.",
            "Click here to go to a Storybuilder chronology",
            "Click here to go to the Timeline tab",
            "Add People here",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: excludes([
            RecommendationNavigationPage.REVIEW,
            RecommendationNavigationPage.CHRON,
        ]),
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    POST_CREATE_NEW_ASSIGNMENT_GROUP: new Recommendation({
        displayName: "Create new assignment group",
        messages: [
            "Congrats! Your reviewers have completed their assignments. If you'd like to "
                + "create another assignment group, click next.",
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Create new"),
                " to get started with your next assignment group. For a refresher on how to "
                    + "create one, select Start Tutorial.",
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.AssignmentGroups,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: [RecommendationNavigationPage.HOME],
        hasPermission: (props) => props.userPerms.canCreateAGs,
    }),
    RECOMMEND_TO_CLUSTER: new Recommendation({
        displayName: "Data Exploration: Cluster document set",
        messages: [
            "Kick off document clustering to explore your document set by conceptual "
                + "similarity. Clusters help you surface key terms and prioritize documents "
                + "for targeted review.",
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Document Clustering"),
            ),
            "Select documents to cluster. You can choose to cluster your full document "
                + "set, or build a search to explore a specific subset.",
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Cluster"),
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.DocumentClustering,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        navigationPage: RecommendationNavigationPage.HOME,
        hasPermission: (props) => props.userPerms.canAdminClustering,
        isDefaultRecommendation: true,
    }),
    RECOMMEND_TO_VIEW_ANALYTICS: new Recommendation({
        displayName: "Manage Review: Monitor reviewer analytics",
        messages: [
            React.createElement(
                "span",
                null,
                "Understand review activity and progress to better manage your document review. "
                    + "Select ",
                React.createElement(Span.Semibold, null, "Project Management"),
                ".",
            ),
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Project Analytics"),
            ),
            React.createElement(
                "span",
                null,
                "The ",
                React.createElement(Span.Semibold, null, "Project Overview"),
                " tab gives you a snapshot of your project's document review so far. Select ",
                React.createElement(Span.Semibold, null, "Review Activity"),
                " to assess individual reviewer performance.",
            ),
            "To learn more about project analytics, start this tutorial",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Analytics,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        navigationPage: RecommendationNavigationPage.HOME,
        hasPermission: (props) => props.userPerms.canAnalytics,
        isDefaultRecommendation: true,
    }),
    RECOMMEND_TO_VIEW_NEW_USERS: new Recommendation({
        displayName: "View new users",
        messages: [
            "Other administrators have added additional users to this project. "
                + "To view these users, first click on this Project Management icon.",
            "Click here to access Project Settings",
            "Now click on the the Users tab. New users will appear here.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    SEARCH_BY_PARTIES: new Recommendation({
        displayName: "Search: Use the Parties search term",
        messages: [
            "Simplify your search with the Parties search term. Parties searches across "
                + "the To, From, Cc, and Bcc fields at once to surface emails between key entities.",
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Show more terms"),
                " to access the Parties search term",
            ),
        ],
        recLevel: RecommendationLevel.EVERYTIME_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.SEARCH_BUILDER],
        navigationPage: RecommendationNavigationPage.SEARCH_BUILDER,
        isDefaultRecommendation: true,
    }),
    SEARCH_BY_PROJECT: new Recommendation({
        displayName: "Search by project",
        messages: [
            "Now that you have two projects in the database, you can use the Projects search term. "
                + "The Projects search term limits searches to uploads made to specific projects. Click "
                + "here to access the query builder.",
            "The Projects search term is in the Document section of search terms. Click here to access it.",
            "Click here to add the Projects term to your search",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        navigationPage: RecommendationNavigationPage.DATABASE_SETTINGS,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canDbAdmin,
    }),
    SELF_ASSIGN: new Recommendation({
        displayName: "Self-assign a batch of documents",
        messages: [
            "Congratulations on finishing your first assignment! Select the plus button to "
                + "claim your next batch of documents.",
            "Click on this assignment to assign yourself more documents from it",
            "Click here when you've chosen the number of documents you'd like to self assign",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.AssignmentGroups,
        supportedPages: [RecommendationNavigationPage.HOME],
        navigationPage: RecommendationNavigationPage.HOME,
        isDefaultRecommendation: true,
    }),
    SET_UP_AUTOCODE: new Recommendation({
        displayName: "Case Setup: Configure auto-code rules",
        messages: [
            React.createElement(
                "span",
                null,
                "Auto-code rules ensure all documents in a given context "
                    + "(e.g. attachments or duplicates) are automatically coded the same way. "
                    + "This standardization reduces errors and time spent on manual coding. "
                    + "Select ",
                React.createElement(Span.Semibold, null, "Auto-Code Rules"),
                ".",
            ),
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        supportedPages: [RecommendationNavigationPage.SETTINGS],
        hasPermission: (props) => props.userPerms.canProjectAdmin,
        isDefaultRecommendation: true,
    }),
    SET_UP_CODING_PRESETS: new Recommendation({
        displayName: "Efficient Review: Create coding presets",
        messages: [
            "Coding presets are custom shortcuts that can apply several codes, ratings, "
                + "binders, or notes with a single click. Select Next to go to the coding presets tab.",
            "To set up coding presets, you’ll need to edit your review window to add the coding presets tab",
            "First, add the coding presets tab to the panel",
            'Finish editing by clicking "Done"',
            "The coding presets tab shows all your saved presets",
            "Select the pencil to create a preset",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
        isDefaultRecommendation: true,
    }),
    SET_UP_CONDITIONAL_CODES: new Recommendation({
        displayName: "Case Setup: Configure conditional coding rules",
        messages: [
            React.createElement(
                "span",
                null,
                "Create conditional coding rules to prevent certain codes from "
                    + "being applied unless conditions are met. Coding rules reinforce reviewer "
                    + "consistency and simplify future QA. Select ",
                React.createElement(Span.Semibold, null, "Conditional Rules"),
                ".",
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.ProjectSettings,
        supportedPages: [RecommendationNavigationPage.SETTINGS],
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    SET_UP_SEARCH_GROUPING: new Recommendation({
        displayName: "Search: Group results",
        messages: [
            React.createElement(
                "span",
                null,
                "Now that you've created a search, you may want to group your "
                    + "results to add further context. You can group documents by duplicates, "
                    + "attachment family, and more from ",
                React.createElement(Span.Semibold, null, "Settings"),
                ".",
            ),
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
        isDefaultRecommendation: true,
    }),
    SHARE_BINDER: new Recommendation({
        displayName: "Share a binder",
        messages: ["You can share this binder from this 3 dot menu"],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: [RecommendationNavigationPage.HOME],
    }),
    SHARE_FOLDER: new Recommendation({
        displayName: "Collaboration: Share homepage folders",
        messages: [
            "Folders are collaborative objects that can be shared. Open your folder to begin.",
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Share"),
                ". You can choose both who to share the folder with, and which level of "
                    + "permissions they should have.",
            ),
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: [RecommendationNavigationPage.HOME],
    }),
    SHARE_PRODUCTION: new Recommendation({
        displayName: "Share a production",
        messages: [
            "After creating a production, you may want to share that production with other "
                + "users. Click here to share your production.",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Productions,
        supportedPages: [RecommendationNavigationPage.PRODUCTIONS],
        hasPermission: (props) => props.userPerms.canShareProductions,
    }),
    SMART_ONBOARDING_INTRO: new Recommendation({
        displayName: "Introduction to Smart Recommendations",
        messages: [
            React.createElement(
                "span",
                null,
                React.createElement(
                    "span",
                    null,
                    "Smart recommendations suggest actions to take and platform areas "
                        + "to explore given your activity. Recommendations are currently ",
                    React.createElement(Span.Semibold, null, "disabled"),
                    " in this project.",
                ),
                React.createElement("br"),
                React.createElement("br"),
                "Select the Help button to view relevant recommendations and access "
                    + "all Everlaw help resources.",
            ),
            "Opt into smart recommendations for all or some areas of Everlaw from this menu",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: excludes([]),
        sbbeAccessible: true,
        bypassesPreferences: true,
    }),
    STORYBUILDER_TODO_LIST_ADD: new Recommendation({
        displayName: "Collaboration: Manage deposition tasks in Storybuilder",
        messages: [
            "Now that you've scheduled a deposition, keep track of important tasks by "
                + "adding and assigning items with a due date in your Tasks list",
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        supportedPages: [RecommendationNavigationPage.SB_DASHBOARD],
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
        sbbeAccessible: true,
    }),
    STORY_LABELS_EVENTS: new Recommendation({
        displayName: "Storybuilder: Case Timeline",
        messages: [
            "Once key documents are added to your Story, sort and label them from your "
                + "Timeline to build your case narrative. Select the Storybuilder button to learn more.",
            "Select a Story",
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Timeline"),
            ),
            React.createElement(
                "span",
                null,
                "To learn how Timeline works, select ",
                React.createElement(Span.Semibold, null, "Start tutorial"),
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        supportedPages: excludes([
            RecommendationNavigationPage.REVIEW,
            RecommendationNavigationPage.CHRON,
        ]),
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
        isDefaultRecommendation: true,
    }),
    TEST_REC: new Recommendation({
        displayName: "Test recommendation",
        messages: [
            "This is an activated recommendation. Notice that it may point to a frontend element. "
                + "To advance the rec, click next.",
            "You can also have recommendations render as toasts. Click Done to finish the recommendation.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Test,
        supportedPages: [RecommendationNavigationPage.STYLE],
    }),
    TRANSFER_WORK_PRODUCT: new Recommendation({
        displayName: "Transfer work product",
        messages: [
            "We've noticed that you have documents on multiple projects. "
                + "To learn how to transfer work product from the other project, start a walkthrough tutorial.",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
    }),
    UNITIZE_DOCUMENT: new Recommendation({
        displayName: "Efficient Review: Unitize document",
        messages: [
            "If you need to split this document up into several documents, select the "
                + "unitization tool from the context panel",
        ],
        recLevel: RecommendationLevel.EVERYTIME_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
        hasPermission: (props) => props.userPerms.canUnitize,
        isDefaultRecommendation: true,
    }),
    VIEW_DEPOSITION: new Recommendation({
        displayName: "Storybuilder: View Depositions",
        messages: [
            "A deposition has been scheduled by someone on your team. Select the "
                + "Storybuilder button to view the Deposition.",
            "Now click here to select your Storybuilder chronology",
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Depositions"),
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Storybuilder,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canReceiveSB,
        shouldCheckChronAccess: true,
    }),
    VIEW_KEYBOARD_SHORTCUTS: new Recommendation({
        displayName: "Efficient Review: Keyboard shortcuts",
        messages: ['To view keyboard shortcuts for any page, press "?" on your keyboard'],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.ReviewWindow,
        supportedPages: [RecommendationNavigationPage.REVIEW],
        isDefaultRecommendation: true,
    }),
    VIEW_NEW_CODES: new Recommendation({
        displayName: "View new codes",
        messages: [
            "A user on your project has added new codes. To view these codes, "
                + "first click on this Project Management icon.",
            "Click here to access Project Settings",
            "Select the Codes tab to see the new codes",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        navigationPage: RecommendationNavigationPage.HOME,
        hasPermission: (props) => props.userPerms.canProjectAdmin,
    }),
    VIEW_NEW_DOCS: new Recommendation({
        displayName: "View new documents",
        messages: [
            "A user on this project has uploaded additional documents. To view this upload, click on the data transfer menu first.",
            "Now click on the uploads tab",
            "To view the natively uploaded documents, click the Native Data Tab",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Uploads,
        navigationPage: RecommendationNavigationPage.HOME,
        supportedPages: excludes([RecommendationNavigationPage.REVIEW]),
        hasPermission: (props) => props.userPerms.canUpload,
    }),
    VIEW_SAVING: new Recommendation({
        displayName: "Search: Customize results table views",
        messages: [
            React.createElement(
                "span",
                null,
                "Customize the information displayed in your results table to view the "
                    + "most important information about your documents. Select ",
                React.createElement(Span.Semibold, null, "View"),
                ".",
            ),
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Add or remove columns"),
            ),
            React.createElement(
                "span",
                null,
                "Select the columns you want to see in the results table. Then select ",
                React.createElement(Span.Semibold, null, "Save"),
                ".",
            ),
            React.createElement(
                "span",
                null,
                "Once you are satisfied with the information displayed, save your view from the ",
                React.createElement(Span.Semibold, null, "View"),
                " menu",
            ),
            React.createElement(
                "span",
                null,
                "Select ",
                React.createElement(Span.Semibold, null, "Save view"),
            ),
        ],
        recLevel: RecommendationLevel.PROJECT_LEVEL,
        pagePref: RecommendationPreferencePage.Search,
        supportedPages: [RecommendationNavigationPage.RESULTS_TABLE],
        isDefaultRecommendation: true,
    }),
    WELCOME_TO_EVERLAW: new Recommendation({
        displayName: "Welcome to Everlaw",
        messages: [
            React.createElement(
                "span",
                null,
                React.createElement(
                    "span",
                    null,
                    "Welcome to Everlaw! Smart Recommendations are currently ",
                    React.createElement(Span.Semibold, null, "enabled"),
                    " in this project.",
                ),
                React.createElement("br"),
                React.createElement("br"),
                "Periodically, Everlaw will suggest actions to take and areas to explore "
                    + "given your project activity.",
                React.createElement("br"),
                React.createElement("br"),
                "Select the Help button to view relevant recommendations and access all "
                    + "Everlaw help resources. ",
            ),
            "To opt into additional smart recommendations, or to disable recommendations, "
                + "select the settings button",
            "You can disable all recommendations in the project, or recommendations on a "
                + 'specific topic or page. Once you\'ve finished selecting which recommendations to disable, select "Done."',
            "To get started on your project, click here to start a tutorial on the homepage",
        ],
        recLevel: RecommendationLevel.USER_LEVEL,
        pagePref: RecommendationPreferencePage.Homepage,
        supportedPages: excludes([]),
        sbbeAccessible: true,
        bypassesPreferences: true,
    }),
} as const satisfies Record<string, Recommendation>;

Object.keys(Recommendations).forEach((key) => {
    const recConstantKey = key as keyof typeof Recommendations;
    const recommendation = Recommendations[recConstantKey];
    recommendation.setRecommendationKey(recConstantKey);
});

/**
 * Gets {@link Recommendation recommendations} the current user has minimum permissions to view.
 */
export function getRecommendationsWithPermissions(): Recommendation[] {
    const userPerms = checkPermissions();
    const chronsVisible = hasVisibleChrons();
    const perms = { userPerms, hasVisibleChrons: () => chronsVisible };
    return Object.values(Recommendations).filter((rec) => rec.hasPermission(perms));
}
