/*
 * Including this file makes dojo use our custom dnd manager, which has a nicer avatar for DND.
 *
 * For clarity, DnD stands for "drag n drop".
 */
/// <amd-dependency path="dojo/NodeList-traverse" /> // Traverse adds methods to NodeList.
import { Arr, Str } from "core";
import { ZIndexTokens } from "design-system";
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import array = require("dojo/_base/array");
import connect = require("dojo/_base/connect");
import declare = require("dojo/_base/declare");
import dojo_dnd_Avatar = require("dojo/dnd/Avatar");
import dojo_dnd_Manager = require("dojo/dnd/Manager");
import dojo_dnd_Source = require("dojo/dnd/Source");
import dojo_dnd = require("dojo/dnd/common");
import on = require("dojo/on");
import dojo_query = require("dojo/query");

/* Currently, this avatar only works with single item selections, and requires
 * sources to provide creators. */
export const Avatar = declare(dojo_dnd_Avatar, {
    construct: function () {
        const a = Dom.create("div", {
            class: "dnd-avatar",
            style: {
                position: "absolute",
                zIndex: ZIndexTokens.TOOLTIP - 1,
                margin: "0px",
                opacity: 0.6,
            },
        });
        const header = Dom.create("div", { class: "dojoDndAvatarHeader avatar-marker" }, a);
        const content = Dom.create("div", { class: "avatar-content", style: { float: "left" } }, a);
        // this.manager.nodes should be a list of all the moving DnD nodes - if this is not empty,
        // then add some content based on the first one (we're assuming single item selections).
        // If it is empty, then something probably went wrong... just use an empty avatar (this is
        // what dojo's default avatar implementation does).
        if (this.manager.nodes[0]) {
            const source = this.manager.source;
            const avatarInfo = source._normalizedCreator(
                source.getItem(this.manager.nodes[0].id).data,
                "avatar",
            );
            const node = avatarInfo.node;
            if (avatarInfo.icon) {
                new Icon(avatarInfo.icon, { parent: header });
            }
            content.appendChild(node);
        }
        this.node = a;
    },
    update: function () {
        // summary:
        //		updates the avatar to reflect the current DnD state
        Dom.toggleClass(this.node, "dojoDndAvatarCanDrop", this.manager.canDropFlag);
    },
});

export const Manager = declare(dojo_dnd_Manager, {
    OFFSET_X: -30,
    OFFSET_Y: 10,
    makeAvatar: function () {
        return new Avatar(this);
    },
});

// make everything use our manager
dojo_dnd._manager = new Manager();
export const manager = dojo_dnd._manager;

/*
 * EmbeddedSource: A custom source that supports a source nested within another source.
 *
 * The following flags can be used when creating an EmbeddedSource:
 *
 * horizontalNoWrap: When horizontal=true is specified, EmbeddedSource uses some complex
 *                   placeholder logic to support items potentially being displayed in multiple rows
 *                   (such as on the draft page). However this logic makes the assumption that all
 *                   Dnd elements (and the placeholder) are the same width. If this is not the case,
 *                   the logic breaks and the placeholder can alternate before/after the hovered
 *                   element on each mouse movement. If horizontal wrapping is not needed by your
 *                   source, you can set this flag and that logic will be bypassed, falling back to
 *                   a simple placeholder calculation that works for differently-sized elements.
 *
 * noPlaceholder: If set, logic for creating/removing placeholders will be bypassed.
 *
 * parentSource: If this source is nested within another source, the parent source must be specified
 */
export type EmbeddedSource = any; // Placeholder until EmbeddedSource hierarchy can be properly typed
export const EmbeddedSource = declare(dojo_dnd_Source, {
    findData: function (data) {
        const allNodes = this.getAllNodes();
        let ret = null;
        allNodes.forEach((n, i) => {
            if (data === this.getItem(n.id).data) {
                ret = { index: i, node: n };
            }
        });
        return ret;
    },
    insertDataAt: function (data, index) {
        if (index >= this.parent.children.length) {
            this.insertNodes(false, data);
        } else {
            this.insertNodes(false, data, true, this.parent.children[index >= 0 ? index : 0]);
        }
    },
    removeData: function (data) {
        const result = this.findData(data);
        if (result) {
            this.delItem(result.node.id);
            return true;
        }
        return false;
    },
    getAllData: function () {
        const data = [];
        this.getAllNodes().forEach((n) => data.push(this.getItem(n.id).data));
        return data;
    },
    removeAllData: function () {
        const nodes: HTMLElement[] = this.getAllNodes();
        nodes.forEach((n) => this.delItem(n.id));
    },
    getNumNodes: function () {
        return this.parent.children.length;
    },

    // mouse events
    onMouseOver: function (e) {
        // summary:
        //		event processor for onmouseover
        // e: Event
        //		mouse event
        let n = e.relatedTarget;
        while (n) {
            if (n === this.parent) {
                break;
            }
            try {
                n = n.parentNode;
            } catch (x) {
                n = null;
            }
        }
        if (!n) {
            this._changeState("Container", "Over");
            // Monkeypatched from Source.onOverEvent.  This is necessary to ensure
            // the correct source is the current source in the Dnd Manager.
            dojo_dnd_Source.superclass.onOverEvent.call(this);
            if (!this._isOverChildSource(e)) {
                dojo_dnd_Manager.manager().overSource(this);
            }
            if (this.isDragging && this.targetState !== "Disabled") {
                this.onDraggingOver();
            }
        }
        n = this._getChildByEvent(e);
        if (this.current === n) {
            return;
        }
        if (this.current) {
            this._removeItemClass(this.current, "Over");
        }
        if (n) {
            this._addItemClass(n, "Over");
        }
        // There's a weird case that occurs when hovering over nodes in a source and the
        // mouseover command is triggered for the parent source dom node.  In this case,
        // we shouldn't update the current node.
        if (n || (!n && this._isOverChildSource(e))) {
            this.current = n;
        }
    },
    _isContentEditable: function (evt) {
        let el = <HTMLElement>evt.target;
        while (el && (Dom.hasClass(el, "dndIgnore") || !el.isContentEditable)) {
            el = el.parentElement;
        }
        return !!el;
    },
    onSelectStart: function onSelectStart(e) {
        // dojo normally stops text select from happening, but we don't want this to happen
        // unconditionally.
        // See onSelectStart in dojo/dnd/Container.js for where it traps this event.
        if (!this.allowSelect && !this._isContentEditable(e)) {
            this.inherited({ callee: onSelectStart }, arguments);
        }
    },
    onMouseDown: function onMouseDown(evt) {
        // Don't trap clicks on input placeholders - let them go through to the focus manager. dojo
        // knows to ignore most form elements, but not input placeholder spans.
        // Reported as bug #17133; this was closed as patchwelcome, but remove condition if fixed.
        if (
            !Dom.hasClass(evt.target, "dijitPlaceHolder")
            // Don't trap clicks on actionable buttons - let them activate the button without
            // initiating a drag.
            && !Dom.hasClass(evt.target, "action")
            // Don't trap clicks that are on empty drag and drop space. dojoDnd{Type} changes to
            // dojoDnd{Type}Over on hover. Solves issue of three-dot menu not going away after
            // clicking on empty Dnd space or the unoccupied part of an item.
            && !(
                Dom.hasClass(evt.target, "dojoDndContainerOver")
                || Dom.hasClass(evt.target, "dojoDndItemOver")
            )
            && !this._isContentEditable(evt)
        ) {
            this.inherited({ callee: onMouseDown }, arguments);
        }
    },
    onMouseMove: function (e) {
        // summary:
        //		event processor for onmousemove
        // e: Event
        //		mouse event
        if (this.isDragging && this.targetState === "Disabled") {
            return;
        }
        // If the mouse is over a child source, do nothing (except remove any
        // existing placeholders).
        const overChildSource = this._isOverChildSource(e);
        if (overChildSource) {
            this._removePlaceholder();
            return;
        }

        dojo_dnd_Source.superclass.onMouseMove.call(this, e);

        if (!this.isDragging) {
            if (
                this.mouseDown
                && this.isSource
                && (Math.abs(e.pageX - this._lastX) > this.delay
                    || Math.abs(e.pageY - this._lastY) > this.delay)
            ) {
                const nodes = this.getSelectedNodes();
                // Only start a drag if you're actually clicking on the selected item.
                // This way, you can drag a Source by clicking on it (in the old code,
                // clicking anywhere on a source would initiate a drag for that
                // Source's selected child element).
                const parent = Dom.firstAncestor(e.target, "dojoDndItem", "dojoDndItemOver");
                if (nodes.length && array.indexOf(nodes, parent) >= 0) {
                    dojo_dnd_Manager
                        .manager()
                        .startDrag(this, nodes, this.copyState(connect.isCopyKey(e), true));
                }
            }
        }
        if (this.isDragging) {
            // calculate before/after
            let before = false;
            if (this.horizontal) {
                // For the horizontal case, the source needs to be able to display
                // items potentially in multiple rows.  In order to handle this,
                // the logic of placing the placeholder has to be modified.
                let hasPlaceholderBefore = false;
                // If current has been set.
                let currentSet = false;
                // Iterate over the nodes searching for the first node where the cursor
                //
                for (let i = 0; i < this.parent.children.length; i++) {
                    const child = this.parent.children[i];
                    const childBox = Dom.position(child, true);
                    if (childBox.h === 0 || childBox.w === 0) {
                        continue;
                    }
                    if (Dom.hasClass(child, "placeholder")) {
                        hasPlaceholderBefore = true;
                        continue;
                    }
                    const isBefore =
                        // above the top of the box, or to the left and above the bottom
                        e.pageY <= childBox.y
                        || (e.pageX <= childBox.x && e.pageY <= childBox.y + childBox.h);
                    const isInside =
                        e.pageY <= childBox.y + childBox.h && e.pageX <= childBox.x + childBox.w;
                    if (isBefore || isInside) {
                        this.current = child;
                        currentSet = true;
                        if (this.horizontalNoWrap) {
                            before = e.pageX - childBox.x < childBox.w / 2;
                        } else {
                            before = isBefore ? true : !hasPlaceholderBefore;
                        }
                        break;
                    }
                }
                //If we haven't updated current, this means the current cursor position
                //does not appear before any nodes and we need to set the anchor node to be the last node.
                if (!currentSet) {
                    if (this.parent.children.length > 0) {
                        const lastIndex = this.parent.children.length - 1;
                        const lastChild = this.parent.children[lastIndex];
                        const isPlaceholder = Dom.hasClass(lastChild, "placeholder");
                        if (isPlaceholder) {
                            if (lastIndex === 0) {
                                // In this case the placeholder is the only node in the source.
                                this.current = null;
                            } else {
                                // The lastChild is a placeholder, but there's more than one node.
                                this.current = this.parent.children[lastIndex - 1];
                            }
                        } else {
                            this.current = lastChild;
                        }
                    } else {
                        //There are no children or placeholders.
                        this.current = null;
                    }
                }
            } else {
                // ! this.horizontal
                if (this.current) {
                    if (!this.targetBox || this.targetAnchor !== this.current) {
                        this.targetBox = Dom.position(this.current, true);
                    }
                    before = e.pageY - this.targetBox.y < this.targetBox.h / 2;
                }
            }
            this._markTargetAnchor(before);
            const isAncestor = dojo_dnd_Manager.manager().nodes.some(this._isAncestor, this);
            // Can drop over current selection to "do nothing" on move,
            // or duplicate on copy. But can't drop into yourself (or a
            // child of yourself.)
            // Only change the canDrop status if you are the actual nested
            // source that's being dropped on. Other parent sources don't
            // get a vote!
            if (!overChildSource) {
                dojo_dnd_Manager.manager().canDrop(!isAncestor);
            }
        }
        return;
    },
    onOutEvent: function () {
        // summary:
        //		this function is called once, when mouse is out of our container
        // Remove any existing placeholders. For instance if we added one to
        // an empty container without a current item, it will linger around
        // until we remove it explicitly.
        this._removePlaceholder();
        dojo_dnd_Source.superclass.onOutEvent.call(this);
        if (this.isDragging && this.targetState !== "Disabled") {
            this.onDraggingOut();
        }
        // If this is a nested source, enable the drop for the outer source.
        dojo_dnd_Manager.manager().outSource(this);
        if (this.parentSource) {
            dojo_dnd_Manager.manager().overSource(this.parentSource);
        }
    },
    onDropInternal: function onDropInternal(nodes, copy) {
        // We override this function because the dojo implementation does nothing
        // on a drop if the actionable (current) element is in the selection.
        // However, if we are copying nodes right above the selected node, we
        // want that copy to occur. (In other words, you can't move an item onto
        // itself, but you can definitely copy it next to itself.)
        // Rather than paste in the entire onDropInternal function to skip that
        // check on copy, we remove the selection here right before attempting
        // the move.
        this._removeSelection();
        this.inherited({ callee: onDropInternal }, arguments);
    },
    onDndCancel: function onDndCancel() {
        // Need to make sure we remove placeholders even for sources without target
        // anchors.
        this._removePlaceholder();
        this.inherited({ callee: onDndCancel }, arguments);
    },
    _isAncestor: function (node: Element): boolean {
        return node?.contains(this.parent);
    },
    _markTargetAnchor: function (before) {
        // summary:
        //		assigns a class to the current target anchor based on "before" status
        // before: Boolean
        //		insert before, if true, after otherwise
        // Check node children to handle case of empty container: we still want
        // to add a placeholder there.
        if (!this.before) {
            this.before = false;
        }
        if (
            this.current === this.targetAnchor
            && this.before === before
            && dojo_query("> .item-box", this.parent).length > 0
        ) {
            return;
        }
        // don't show anything if we're not accepting
        if (
            !this.checkAcceptance(
                dojo_dnd_Manager.manager().source,
                dojo_dnd_Manager.manager().nodes,
            )
        ) {
            return;
        }
        // can't drop yourself inside yourself, so don't add an anchor
        if (array.some(dojo_dnd_Manager.manager().nodes, this._isAncestor, this)) {
            return;
        }
        this._removePlaceholder();
        this.targetAnchor = this.current;
        this.targetBox = null;
        this.before = before;
        if (this._shouldCreatePlaceholder(before)) {
            dojo_query("> .placeholder.default", this.parent).addClass("hidden");
            if (this.targetAnchor) {
                Dom.place(
                    this._createPlaceholder(),
                    this.targetAnchor,
                    this.before ? "before" : "after",
                );
            } else {
                Dom.place(this._createPlaceholder(), this.parent);
            }
        }
    },
    _shouldCreatePlaceholder: function (before) {
        return !this.noPlaceholder;
    },
    _createPlaceholder: function (noDndSupport = false) {
        const div = Dom.create("div", {
            class:
                "item-box placeholder default-style default-content"
                + (noDndSupport ? " no-dnd" : ""),
        });
        Dom.create("div", { class: "placeholder-inner" }, div);
        return div;
    },
    _removePlaceholder: function () {
        dojo_query("> .placeholder", this.parent).forEach((n) => {
            Dom.hasClass(n, "default") ? Dom.show(n) : Dom.destroy(n);
        });
    },
    createDefaultPlaceholder: function (noDndSupport = false) {
        const ph = this._createPlaceholder(noDndSupport);
        Dom.addClass(ph, "default");
        on(ph, "mouseover", () => {
            this.before = true;
        });
        Dom.place(ph, this.parent);
    },
    removeDefaultPlaceholder: function () {
        dojo_query(" > .placeholder.default", this.parent).forEach((n) => {
            Dom.destroy(n);
        });
    },

    _unmarkTargetAnchor: function () {
        // summary:
        //		removes a class of the current target anchor based on "before" status
        this._removePlaceholder();
        this.targetAnchor = null;
        this.targetBox = null;
        this.before = true;
    },
    _getChildByEvent: function _getChildByEvent(e) {
        // if it's a placeholder node, just return this.current
        if (Dom.firstAncestor(e.target, "placeholder")) {
            return this.current;
        }
        return this.inherited({ callee: _getChildByEvent }, arguments);
    },
    _isOverChildSource: function (e) {
        return this._isInChildSource(e.target);
    },
    _isInChildSource: function (node) {
        return Dom.firstAncestor(node, "dojoDndContainer", "dojoDndContainerOver") !== this.node;
    },
    setIsDndSource: function (set = true) {
        this.isSource = set;
        Dom.toggleClass(this.node, "dojoDndSource", set);
    },
    setSelfAccept: function (set = true) {
        this.selfAccept = set;
    },
});

/*
 * ItemOrganizer - A class for organizing a set of Dnd items into two groups, "pinned" and
 * "unpinned". The unpinned group can be toggled between collapsed (hidden) and expanded (visible).
 * Items can be dragged between groups when the unpinned group is expanded. Items in the unpinned
 * group are always displayed in canonical order, while items in the pinned group can be reordered.
 */

// Dnd source for the pinned group
export type OrganizerPinnedSource = EmbeddedSource;
export const OrganizerPinnedSource = declare(EmbeddedSource, {
    onMouseDown: function onMouseDown(evt) {
        this.inherited({ callee: onMouseDown }, arguments);
        this.organizer.onSourceClick && this.organizer.onSourceClick(evt);
    },
    onDropInternal: function onDropInternal() {
        this.inherited({ callee: onDropInternal }, arguments);
        this.organizer.onChange();
    },
    onDropExternal: function onDropExternal(source, nodes, copy) {
        if (this.reuseNodes) {
            // Manually delete and insert elements so dojo does not destroy the removed node
            const id = nodes[0].id;
            const object = source.getItem(id).data;
            source.delItem(id);
            if (this.targetAnchor) {
                this.insertNodes(false, [object], this.before, this.targetAnchor);
            } else {
                this.insertNodes(false, [object]);
            }
            this._removePlaceholder();
        } else {
            this.inherited({ callee: onDropExternal }, [source, nodes, false]);
        }
        this.organizer.onChange();
    },
});

// Dnd source for the unpinned group
export type OrganizerUnpinnedSource = EmbeddedSource;
export const OrganizerUnpinnedSource = declare(EmbeddedSource, {
    noPlaceholder: true,
    onMouseDown: function onMouseDown(evt: Event) {
        this.inherited({ callee: onMouseDown }, arguments);
        this.organizer.onSourceClick && this.organizer.onSourceClick(evt);
    },
    onDraggingOver: function onDraggingOver() {
        Dom.addClass(this.node, "drop-preview");
        this.inherited({ callee: onDraggingOver }, arguments);
    },
    onDraggingOut: function onDraggingOut() {
        Dom.removeClass(this.node, "drop-preview");
        this.inherited({ callee: onDraggingOut }, arguments);
    },
    // We don't allow rearranging of items in the unpinned source since they are in canonical order,
    // so we bypass the superclass call and take no action (other than the style change).
    onDropInternal: function onDropInternal() {
        Dom.removeClass(this.node, "drop-preview");
    },
    // Add the item in its canonical location.
    onDropExternal: function onDropExternal(source, nodes, copy) {
        const id = nodes[0].id;
        const object = source.getItem(id).data;
        const idx = this.organizer.getUnpinnedInsertIndex(object);
        const nodeList: HTMLElement[] = this.getAllNodes();
        this.before = idx < nodeList.length;
        this.current = idx < nodeList.length ? nodeList[idx] : nodeList[nodeList.length - 1];
        if (this.reuseNodes) {
            // Manually delete and insert elements so dojo does not destroy the removed node
            source.delItem(id);
            if (this.before) {
                this.insertNodes(false, [object], true, nodeList[idx]);
            } else {
                this.insertNodes(false, [object]);
            }
        } else {
            this.inherited({ callee: onDropExternal }, [source, nodes, false]);
        }
        Dom.removeClass(this.node, "drop-preview");
        this.organizer.onChange();
    },
});

export interface ItemOrganizerParams<T> {
    // List of item IDs in canonical order (i.e., how they should appear in the unpinned source).
    itemIds: string[];
    // Callback to get an item's ID
    getItemId: (term: T) => string;
    // Callback when the pinned source changes. Passes the ordered list of pinned IDs.
    onItemChange?: (pinnedIds: string[]) => void;
    // If true, items in the pinned source can be reordered when the unpinned source is collapsed.
    reorderWhenCollapsed?: boolean;
    // Optional CSS class applied to the sources
    class?: string;
    // If you are reusing item nodes (as opposed to re-creating them every time), set this to true
    // and the drag-and-drop operation will manually do the removal to avoid the node in the source
    // from being destroyed.
    reuseItemNodes?: boolean;
    // Params associated with Dnd source creation:
    creator: (item: T, hint: string) => { data: T; node: HTMLElement; type: string[] };
    acceptType: string;
    horizontal?: boolean;
    copyOnly?: boolean;
    createPlaceholder?: () => HTMLElement;
    // Dojo (sometimes) stops propagation of clicks on Dnd.Source elements. If document body clicks
    // need to be propagated, you can provide a handler to get called when onMouseDown occurs.
    onSourceClick?: (evt: Event) => void;
    afterPinnedNode?: HTMLElement;
    afterUnpinnedNode?: HTMLElement;
}

interface UnpinnedSourceParams<T> {
    organizer: ItemOrganizer<T>;
    reuseNodes: boolean;
    creator: ItemOrganizerParams<T>["creator"];
    skipForm: true;
    singular: true;
    copyOnly: boolean;
    selfCopy: false;
    selfAccept: false;
    horizontal: boolean;
    accept: [string];
    delay: number;
    dropParent?: HTMLElement;
}

export class ItemOrganizer<T> {
    pinnedNode: HTMLElement;
    pinnedParent: HTMLElement;
    unpinnedNode: HTMLElement;
    unpinnedParent: HTMLElement;
    itemIds: string[];
    private pinnedSource: OrganizerPinnedSource;
    private unpinnedSource: OrganizerUnpinnedSource;
    private reorderWhenCollapsed: boolean;
    private copyOnly = false;
    private onItemChange: (pinnedIds: string[]) => void;
    private getItemId: (t: T) => string;
    private tempDiv: HTMLElement;
    private onSourceClick: (evt: Event) => void;
    constructor(params: ItemOrganizerParams<T>) {
        this.tempDiv = Dom.div();
        this.itemIds = params.itemIds;
        this.getItemId = params.getItemId;
        this.onItemChange = params.onItemChange;
        this.reorderWhenCollapsed = !!params.reorderWhenCollapsed;
        this.copyOnly = !!params.copyOnly;
        this.pinnedNode = Dom.div({ class: (params.class ? params.class + " " : "") + "pinned" });
        this.onSourceClick = params.onSourceClick;
        const pinnedSourceParams: any = {
            organizer: this,
            reuseNodes: params.reuseItemNodes || false,
            creator: params.creator,
            skipForm: true,
            singular: true,
            copyOnly: this.copyOnly,
            selfCopy: false,
            selfAccept: true,
            horizontal: params.horizontal,
            accept: [params.acceptType],
            delay: 10,
        };
        if (params.afterPinnedNode) {
            this.pinnedParent = Dom.div(this.pinnedNode, params.afterPinnedNode);
            pinnedSourceParams.dropParent = this.pinnedNode;
        } else {
            this.pinnedParent = this.pinnedNode;
        }
        if (params.createPlaceholder) {
            pinnedSourceParams._createPlaceholder = params.createPlaceholder;
        }
        this.pinnedSource = this.newPinnedSource(pinnedSourceParams);
        this.unpinnedNode = Dom.div({
            class: (params.class ? params.class + " " : "") + "unpinned",
        });
        const unpinnedSourceParams: UnpinnedSourceParams<T> = {
            organizer: this,
            reuseNodes: params.reuseItemNodes || false,
            creator: params.creator,
            skipForm: true,
            singular: true,
            copyOnly: this.copyOnly,
            selfCopy: false,
            selfAccept: false,
            horizontal: params.horizontal,
            accept: [params.acceptType],
            delay: 10,
        };
        if (params.afterUnpinnedNode) {
            this.unpinnedParent = Dom.div(this.unpinnedNode, params.afterUnpinnedNode);
            unpinnedSourceParams.dropParent = this.unpinnedNode;
        } else {
            this.unpinnedParent = this.unpinnedNode;
        }
        this.unpinnedSource = this.newUnpinnedSource(unpinnedSourceParams);
    }
    newPinnedSource(params: any) {
        return new OrganizerPinnedSource(this.pinnedParent, params);
    }
    newUnpinnedSource(params: UnpinnedSourceParams<T>) {
        return new OrganizerUnpinnedSource(this.unpinnedParent, params);
    }
    /**
     * Set the contents of the organizer. The items passed here must already exist in the itemIds
     * list. If there are new items not specified at construction time, they must be added first
     * with addItem() before they can be "set" here.
     */
    setItems(pinnedItems: T[], unpinnedItems: T[]) {
        // First clear everything out of both sources:
        this.clearItems();
        // Add the items to the correct source
        if (pinnedItems.length > 0) {
            this.pinnedSource.insertNodes(false, pinnedItems);
        }
        if (unpinnedItems.length > 0) {
            const itemMap: { [id: string]: number } = {};
            this.itemIds.forEach((id, idx) => {
                itemMap[id] = idx;
            });
            // Since we just cleared all our unpinned nodes, we want to sort the new unpinnedItems
            // and then insert all of them in that order.
            // We avoid using insertUnpinnedItem which would be O(N) for each unpinned item.
            this.unpinnedSource.insertNodes(
                false,
                Arr.sorted(unpinnedItems, { key: (item) => itemMap[this.getItemId(item)] }),
            );
        }
    }
    clearItems() {
        this.pinnedSource.removeAllData();
        (<HTMLElement[]>this.pinnedSource.getAllNodes()).forEach((node) => {
            Dom.place(node, this.tempDiv);
        });
        this.unpinnedSource.removeAllData();
        (<HTMLElement[]>this.unpinnedSource.getAllNodes()).forEach((node) => {
            Dom.place(node, this.tempDiv);
        });
    }
    /**
     * Add a new item to the organizer. If pinned is true, it will be added to the end of the
     * pinned source. The idx param indicates its canonical place in the unpinned source.
     *
     * TODO rename this and explain that this function is intended for new items, not for initially
     * populating the source.
     */
    addItem(item: T, pinned = false, idx = this.itemIds.length) {
        this.itemIds.splice(idx, 0, this.getItemId(item));
        if (pinned) {
            this.pinnedSource.insertNodes(false, [item]);
        } else {
            this.insertUnpinnedItem(item);
        }
        this.onChange();
    }
    togglePinnedDisabled(state: boolean) {
        this.pinnedSource.setIsDndSource(!state);
    }
    /**
     * Toggle the unpinned source between expanded and collapsed.
     */
    toggleUnpinned() {
        const wasCollapsed = !this.isExpanded();
        Dom.show(this.unpinnedNode, wasCollapsed);
        if (!this.reorderWhenCollapsed) {
            if (this.copyOnly) {
                this.pinnedSource.setSelfAccept(wasCollapsed);
            } else {
                this.pinnedSource.setIsDndSource(wasCollapsed);
            }
        }
    }
    /**
     * Filters unpinned items so that only those whose id contains the provided substring show.
     * Gives the 'first-matching' class to the first one if the provided substring is not empty.
     */
    filterUnpinned(substring: string) {
        const nodeList: HTMLElement[] = this.unpinnedSource.getAllNodes();
        substring = substring.trim();
        let lookingForFirst = Str.nonempty(substring);
        nodeList.forEach((node) => {
            const itemId = this.getItemIdForUnpinnedNode(node);
            const shouldShow = Str.containsIgnoreCase(itemId, substring);
            Dom.show(node, shouldShow);
            Dom.toggleClass(node, "first-matching", lookingForFirst && shouldShow);
            lookingForFirst = lookingForFirst && !shouldShow;
        });
    }
    /**
     * Gets the first unpinned item whose id contains the provided non-empty substring.
     */
    getFirstMatchingUnpinned(substring: string) {
        substring = substring.trim();
        if (Str.nonempty(substring)) {
            for (const item of this.getUnpinnedItems()) {
                if (Str.containsIgnoreCase(this.getItemId(item), substring)) {
                    return item;
                }
            }
        }
        return null;
    }
    /**
     * Notify on any change to the organizer.
     */
    onChange() {
        if (this.onItemChange) {
            const data = <T[]>this.pinnedSource.getAllData();
            this.onItemChange(data.map((item) => this.getItemId(item)));
        }
    }
    /**
     * Get the index where an item should be inserted into the unpinned source.
     * This is a linear-time operation in the number of elements in this.itemIds!
     */
    private getUnpinnedInsertIndex(item: T) {
        const itemIndex = this.itemIds.indexOf(this.getItemId(item));
        const nodeList: HTMLElement[] = this.unpinnedSource.getAllNodes();
        let i: number;
        for (i = 0; i < nodeList.length; i++) {
            const thisId = this.getItemIdForUnpinnedNode(nodeList[i]);
            if (itemIndex < this.itemIds.indexOf(thisId)) {
                break;
            }
        }
        return i;
    }
    /**
     * Insert an item into the unpinned source.
     */
    private insertUnpinnedItem(item: T) {
        const idx = this.getUnpinnedInsertIndex(item);
        if (idx < this.unpinnedSource.getNumNodes()) {
            const nodes: HTMLElement[] = this.unpinnedSource.getAllNodes();
            this.unpinnedSource.insertNodes(false, [item], true, nodes[idx]);
        } else {
            this.unpinnedSource.insertNodes(false, [item]);
        }
    }
    isExpanded(): boolean {
        return !Dom.isHidden(this.unpinnedNode);
    }
    getPinnedItems(): T[] {
        return this.pinnedSource.getAllData();
    }
    getUnpinnedItems(): T[] {
        return this.unpinnedSource.getAllData();
    }
    getItemIdForUnpinnedNode(node: HTMLElement) {
        return this.getItemId(this.unpinnedSource.getItem(node.id).data);
    }
}
