import * as ActionNode from "Everlaw/UI/ActionNode";
import * as Base from "Everlaw/Base";
import { Compare as Cmp } from "core";
import { EverColor } from "design-system";
import { RedactionStampChips, RedactionStampChipsProps } from "Everlaw/Review/RedactionStampChips";
import { ReactWidget, wrapReactComponent } from "Everlaw/UI/ReactWidget";
import * as Tooltip from "Everlaw/UI/Tooltip";
import * as MultiSelect from "Everlaw/UI/MultiSelect";
import * as Dom from "Everlaw/Dom";
import * as FocusContainerWidget from "Everlaw/UI/FocusContainerWidget";
import { Is, Str } from "core";
import * as Project from "Everlaw/Project";
import { CREATE_REDACTIONS } from "Everlaw/PermissionStrings";
import * as SingleSelect from "Everlaw/UI/SingleSelect";
import { Text } from "Everlaw/UI/Validated";
import { ValidatedSubmit } from "Everlaw/UI/ValidatedSubmit";
import * as User from "Everlaw/User";
import { destroy, Destroyable } from "Everlaw/Util";
import { openExternal } from "Everlaw/Win";
import * as RedactionStamp from "Everlaw/Review/RedactionStamp";
import * as RedactionStampUtil from "Everlaw/Review/RedactionStampUtil";

interface StampCreatorParams {
    submitOnBlur: boolean;
    namePlaceholder: string;
    abbrPlaceholder: string;
    nameWidth: string;
    abbrWidth: string;
    // Margin in between the name and width textboxes. Defaults to 8px if not provided.
    margin?: string;
    // Change aria label parameters to required after all RedactionStamps have been migrated
    nameAriaLabel?: string;
    abbrAriaLabel?: string;
    // Note: If this is accompanied by a ValidatedSubmit button then an onSubmit parameter
    // shouldn't be provided since it will call the ValidatedSubmit submit logic and the
    // onSubmit logic one after the other.
    onSubmit?: (name: string, abbr: string) => void;
}

/**
 * A widget with two textboxes for creating redaction stamps. Automatically fills in abbreviations
 * based on the name provided.
 */
export class RedactionStampCreator {
    node: HTMLElement;
    private nameTb: Text;
    private abbrTb: Text;
    private abbrChanged: boolean;
    private focusContainer: FocusContainerWidget;
    constructor(params: StampCreatorParams) {
        this.node = Dom.div({ style: { display: "inline-block" } });
        const submit = () => {
            params.onSubmit?.(this.nameTb.getValue(), this.abbrTb.getValue());
        };
        if (params.submitOnBlur) {
            this.focusContainer = new FocusContainerWidget(this.node);
            this.focusContainer.onBlur = () => submit();
        }
        const nameWrapper = Dom.create(
            "div",
            {
                style: {
                    display: "inline-block",
                    marginRight: params.margin || "8px",
                    width: params.nameWidth,
                    "vertical-align": "top",
                },
            },
            this.node,
        );

        const lengthTest = () => {
            const nameVal = this.nameTb?.getValue();
            const abbrVal = this.abbrTb?.getValue();
            return nameVal?.length > abbrVal?.length;
        };
        // For stamp creation, instead of displaying an error message that the stamp name is
        // already taken when a user tries to create an exact match of an existing stamp, we
        // allow users to submit a duplicate stamp. This allows us to silently fail on duplicate
        // stamp creation, since the backend will just return the matching stamp in that case.
        this.nameTb = new Text({
            name: params.namePlaceholder,
            placeholderMessage: params.namePlaceholder,
            onChange: () => {
                this.updateAbbr();
            },
            validator: lengthTest,
            invalidMessage: "Stamp name must be longer than abbreviation",
            validateIfTextUnchanged: true,
            errorMessageClass: "custom-stamp-creator__error-message",
            onSubmit: () => submit(),
        });
        if (params.nameAriaLabel) {
            this.setNameAriaLabel(params.nameAriaLabel);
        }
        Dom.place(this.nameTb, nameWrapper);

        const abbrWrapper = Dom.create(
            "div",
            {
                style: { display: "inline-block", width: params.abbrWidth },
            },
            this.node,
        );
        this.abbrTb = new Text({
            name: params.abbrPlaceholder,
            placeholderMessage: params.abbrPlaceholder,
            onChange: () => {
                this.abbrChanged = !!(this.nameTb.getValue() || this.abbrTb.getValue());
            },
            validator: lengthTest,
            invalidMessage: "Abbreviation must be shorter than stamp name",
            validateIfTextUnchanged: true,
            onSubmit: () => submit(),
            required: false,
        });
        this.abbrTb.linkErrorMessage(this.nameTb);
        if (params.abbrAriaLabel) {
            this.setAbbrAriaLabel(params.abbrAriaLabel);
        }
        Dom.place(this.abbrTb, abbrWrapper);
    }
    focus(): void {
        this.nameTb.focus();
    }
    getAbbr(): string {
        return this.abbrTb.getValue();
    }
    setAbbrAriaLabel(ariaLabel: string): void {
        this.abbrTb.input.setTextBoxAriaLabel(ariaLabel);
    }
    getName(): string {
        return this.nameTb.getValue();
    }
    setNameAriaLabel(ariaLabel: string): void {
        this.nameTb.input.setTextBoxAriaLabel(ariaLabel);
    }
    getForms(): Text[] {
        return [this.nameTb, this.abbrTb];
    }
    setValues(name: string, abbr: string): void {
        name ? this.nameTb.setValue(name) : this.nameTb.reset();
        abbr ? this.abbrTb.setValue(abbr) : this.abbrTb.reset();
        this.abbrChanged = !!(name || abbr);
    }
    // Suggests an abbreviation based on the current name if the user hasn't modified the
    // abbreviation yet.
    private updateAbbr() {
        const name = this.nameTb.getValue();
        const abbr = this.abbrTb.getValue();
        if (!name && !abbr) {
            this.abbrChanged = false;
            return;
        }

        if (!this.abbrChanged) {
            if (!Str.isNullOrWhitespace(name)) {
                const newAbbr = name.split(/\s+/).reduce((s: string, cur: string) => {
                    if (cur.length > 0) {
                        s += cur.charAt(0).toUpperCase();
                    }
                    return s;
                }, "");
                if (newAbbr.length > 0) {
                    this.abbrTb.setValue(newAbbr, true);
                }
            }
        }
    }
    destroy(): void {
        this.nameTb.destroy();
        this.abbrTb.destroy();
        if (this.focusContainer) {
            destroy(this.focusContainer);
        }
    }
}

class StampSelectItem extends Base.DataPrimitive<RedactionStamp> {
    constructor(
        public isCustom: boolean,
        public isRecent: boolean,
        public override color: string,
        data: RedactionStamp,
        id: string | number,
        name?: string,
    ) {
        super(data, id, name);
    }
}

interface StampSelectorParams {
    // Title displayed above the selector widget.
    title?: string;
    // If the link to the project's configuration page should be displayed.
    showSettings?: boolean;
    // Should the redaction stamper be disabled
    isDisabled?: boolean;
    // Don't show title, settings, or remove. No Add button with custom stamp creator.
    // For the single stamp creator, custom stamp input takes place of selector and all happens in
    // space of one field.
    // For the multi stamp creator, custom stamp input appears underneath still. Don't show chips.
    isMinimal?: boolean;
    // Allows selection of no stamp
    allowsNoStamp?: boolean;
    // Params to pass to the custom stamp creator to override the defaults
    stampCreatorParams?: Partial<StampCreatorParams>;
}

interface SingleStampSelectorParams extends StampSelectorParams {
    // The stampable object to which to add the stamp.
    stampable?: RedactionStamp.SingleStampable;
    // Callback when a user has specified a stamp to use.
    onStampSelect?: (stamp: RedactionStamp) => void;
}

interface MultiStampSelectorParams extends StampSelectorParams {
    // The stampable object to which to add the stamp.
    stampable?: RedactionStamp.MultiStampable;
    // Callback for multiselect picker when user has changed selected stamps
    onStampsChange?: (stamps: RedactionStamp[]) => void;
}

/**
 * A widget for specifying the text to use for a redaction stamp.  This widget takes into
 * account project settings for custom stamp creation, as well as custom stamps.
 */
abstract class RedactionStampSelector {
    node: HTMLElement;
    protected stampable?: RedactionStamp.SingleStampable | RedactionStamp.MultiStampable;
    protected title: string;
    protected isDisabled: boolean;
    protected remover: HTMLElement;
    protected settings: HTMLElement;
    protected showSettings = true;
    protected allowsNoStamp = true;
    protected selectNode: HTMLElement;
    stampSelector?: SingleSelect<StampSelectItem> | MultiSelect<StampSelectItem>;
    protected customWrapper: HTMLElement;
    protected isMinimal: boolean;
    /**
     * Widget for creating custom stamps. Only appears if customOption is selected or if there
     * are no stamps in stampSelector and the project allows custom stamps.
     */
    protected customCreator: RedactionStampCreator;
    protected toDestroy: Destroyable[] = [];
    protected static NO_STAMP_OPTION = new StampSelectItem(
        false,
        false,
        EverColor.PARCHMENT_30,
        RedactionStamp.NO_STAMP,
        "(No stamp)",
    );
    protected static NEW_CUSTOM_STAMP_OPTION = new StampSelectItem(
        false,
        false,
        EverColor.GREEN_40,
        null,
        "(New custom stamp)",
    );
    // map of stamps to the StampSelectItem options that allow you to select them (needed to make the
    // "Recently used" options be checked and unchecked in tandem with their matching options)
    protected stampToSelectItems: Map<RedactionStamp, StampSelectItem[]> = new Map();

    protected constructor(params: StampSelectorParams) {
        Object.assign(this, params);
        this.initializeTitleAndRemover();
        this.settings = Dom.create("span", {
            class: "redaction-stamper__link redaction-stamper__settings",
            textContent: "Project stamp settings",
        });
        this.isDisabled = Is.defined(params.isDisabled)
            ? params.isDisabled
            : !User.me.can(CREATE_REDACTIONS, Project.CURRENT, User.Override.ELEVATED);
        let settingDiv;
        if (this.isMinimal) {
            settingDiv = null;
        } else {
            settingDiv = Dom.div(
                { class: "redaction-stamper-spacer" },
                Dom.span({ class: "notes-panel-title" }, this.title),
                this.isDisabled ? null : this.remover,
                this.settings,
            );
        }
        this.selectNode = Dom.div();
        this.node = Dom.div({ class: "redaction-stamper" }, settingDiv, this.selectNode);
        if (this.isMinimal) {
            Dom.addClass(this.node, "redaction-stamper--minimal");
        }
        this.toDestroy.push(
            new ActionNode(this.remover, {
                onClick: () => {
                    if (this.stampSelector) {
                        this.stampSelector.select(RedactionStampSelector.NO_STAMP_OPTION);
                        RedactionStampUtil.updateRecentlyUsed(undefined);
                    }
                },
                makeFocusable: true,
                focusStyling: "focus-text-style",
            }),
        );
        !this.allowsNoStamp && Dom.hide(this.remover);
        this.customWrapper = Dom.create("div", {}, this.node);
        Dom.hide(this.customWrapper);
        const onCustomCreatorSubmit = () => {
            if (
                !(
                    Str.isNullOrWhitespace(this.customCreator.getName())
                    && Str.isNullOrWhitespace(this.customCreator.getAbbr())
                )
            ) {
                this.addCustom();
            }
        };
        const customCreatorParams = Object.assign(
            {
                submitOnBlur: this.isMinimal,
                onSubmit: this.isMinimal ? onCustomCreatorSubmit : undefined,
                namePlaceholder: this.isMinimal ? "New stamp" : "Enter custom stamp",
                abbrPlaceholder: this.isMinimal ? "NS" : "Abbr.",
                nameWidth: this.isMinimal ? "94px" : "206px",
                abbrWidth: this.isMinimal ? "44px" : "72px",
                margin: this.isMinimal ? "4px" : "8px",
            },
            params.stampCreatorParams || {},
        );
        this.customCreator = new RedactionStampCreator(customCreatorParams);
        Dom.addClass(this.customCreator, "custom-stamp-creator");
        Dom.place(this.customCreator, this.customWrapper);

        this.toDestroy.push(
            new ActionNode(this.settings, {
                onClick: () => {
                    openExternal(
                        Project.getCurrentProject().url("settings.do#tab=production-tools"),
                        "projectSettings",
                    );
                },
                makeFocusable: true,
                focusStyling: "focus-text-style",
            }),
        );
        Dom.show(this.settings, User.me.isProjectAdmin() && this.showSettings);
        this.toDestroy.push(this.customCreator);

        if (!this.isMinimal) {
            const customBtn = new ValidatedSubmit({
                buttonParams: {
                    parent: this.customWrapper,
                    class: "safe important skinny",
                    width: "72px",
                    style: { margin: "0 0 0 8px" },
                    label: "Add",
                    onClick: onCustomCreatorSubmit,
                },
                forms: this.customCreator.getForms(),
                class: "custom-stamp-creator__add-button",
            });
            this.toDestroy.push(customBtn);
        }
    }

    abstract initializeTitleAndRemover(): void;

    protected async addCustom(): Promise<RedactionStamp> {
        const name = this.customCreator.getName();
        const abbr = this.customCreator.getAbbr();
        const stamp = await RedactionStampUtil.addStamp(name, abbr, false);
        this.customCreator.setValues("", "");
        const stampItem = stamp.isProjectStamp()
            ? RedactionStampSelector.wrapProjectPrim(stamp)
            : RedactionStampSelector.wrapCustomPrim(stamp);
        if (!this.stampSelector) {
            return stamp;
        }
        const stampItemAdded = this.stampSelector.add(stampItem);
        if (stampItemAdded) {
            this.stampToSelectItems.set(stamp, [stampItem]);
        }
        this.stampSelector.select(stampItem);
        return stamp;
    }

    protected static wrapCustomPrim(stamp: RedactionStamp) {
        return new StampSelectItem(true, false, EverColor.GREEN_40, stamp, stamp.display());
    }

    protected static wrapProjectPrim(stamp: RedactionStamp) {
        return new StampSelectItem(false, false, EverColor.EVERBLUE_40, stamp, stamp.display());
    }

    protected static wrapRecentlyUsedPrim(stamp: RedactionStamp, isCustom: boolean) {
        return new StampSelectItem(
            isCustom,
            true,
            isCustom ? EverColor.GREEN_40 : EverColor.EVERBLUE_40,
            stamp,
            stamp.display(),
        );
    }

    /**
     * Builds the StampSelectItems representing stamps in the dropdown, and returns a map of stamps
     * to the StampSelectItems that allow you to select them.
     */
    protected static getStampToSelectItems(
        projectStamps: RedactionStamp[],
        customStamps: RedactionStamp[],
        currentStamps: RedactionStamp[],
        allowsNoStamp: boolean,
    ): Map<RedactionStamp, StampSelectItem[]> {
        // Terminology:
        //      Project Stamp: The stamp matches a stamp on the project settings.
        //      Custom Stamp: The stamp doesn't match a stamp on the project settings.
        //
        // Some important interactions to note:
        // - "No Stamp" is an option if this selector allows it (it's a selector parameter).
        // - "(Custom Stamp)" is only an option if the project allows custom stamps.
        // - The custom stamp creator is only used when a project allows custom stamps.
        // - Custom stamps only appear in dropdown when project settings allow custom stamps.
        // - Recently used stamps appear in dropdown when there are >= 10 project + custom stamps
        // - If a current stamp doesn't match a stamp in the dropdown (because custom stamps are
        //      turned off), then it needs to be added to the dropdown and selected.
        const stampToSelectItems: Map<RedactionStamp, StampSelectItem[]> = new Map();

        if (allowsNoStamp) {
            stampToSelectItems.set(RedactionStamp.NO_STAMP, [
                RedactionStampSelector.NO_STAMP_OPTION,
            ]);
        }
        projectStamps.forEach((s) => {
            stampToSelectItems.set(s, [RedactionStampSelector.wrapProjectPrim(s)]);
        });
        customStamps.forEach((s) => {
            stampToSelectItems.set(s, [RedactionStampSelector.wrapCustomPrim(s)]);
        });
        // A currently applied stamp might not already be present in the dropdown if it is a custom
        // stamp and the custom stamp setting has since been turned off for this project. Then we
        // add the stamp to the dropdown.
        currentStamps.forEach((s) => {
            if (!stampToSelectItems.has(s)) {
                const selectItem = s.isProjectStamp()
                    ? RedactionStampSelector.wrapProjectPrim(s)
                    : RedactionStampSelector.wrapCustomPrim(s);
                stampToSelectItems.set(s, [selectItem]);
            }
        });

        const recentStamps = Base.get(RedactionStamp, RedactionStampUtil.getRecentStamps());
        // If there are at least 10 stamps in the project (project + custom), we show up to 3 recently used stamps.
        if (customStamps.length + projectStamps.length >= 10) {
            recentStamps.forEach((s) => {
                const recentSelectItem = RedactionStampSelector.wrapRecentlyUsedPrim(
                    s,
                    !s.isProjectStamp(),
                );
                const matchingSelectItems = stampToSelectItems.get(s);
                if (!matchingSelectItems) {
                    throw Error(
                        "Recently used stamp does not match a visible project/custom stamp",
                    );
                }
                matchingSelectItems.push(recentSelectItem);
            });
        }

        return stampToSelectItems;
    }

    /**
     * Gets the StampSelectItems to show in the stamp dropdown. Requires this.stampToSelectItems to
     * be already initialized.
     */
    protected getStampItems(allowsCustom: boolean): StampSelectItem[] {
        const stampItems = Array.from(this.stampToSelectItems.values()).flat();
        if (allowsCustom) {
            stampItems.push(RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION);
        }
        return stampItems;
    }

    protected static getStampSelectorParams(): object {
        const projectStampHeader = "Project stamps";
        const customStampHeader = "Custom stamps";
        const recentlyUsedHeader = "Recently used";
        const noHeader = "";
        const recentStamps: number[] = RedactionStampUtil.getRecentStamps();

        return {
            classOrder: [noHeader, recentlyUsedHeader, projectStampHeader, customStampHeader],
            getHeader: (item: StampSelectItem) => {
                if (!item.data || item.data.equals(RedactionStamp.NO_STAMP)) {
                    return noHeader;
                }
                if (item.isRecent) {
                    return recentlyUsedHeader;
                }
                return item.data.isProjectStamp() ? projectStampHeader : customStampHeader;
            },
            pluralize: false,
            ellipsifyText: true,
            mirrorTooltips: true,
            mirrorTooltipPosition: ["after"],
            popupClass: "redaction-stamper__popup",
            comparator: (a: StampSelectItem, b: StampSelectItem) => {
                // none option on top,
                // new custom option next
                // recently used stamps (ordered by most to least recent)
                // project stamps
                // custom stamps
                if (a === b) {
                    return 0;
                }
                if (a === RedactionStampSelector.NO_STAMP_OPTION) {
                    return -1;
                }
                if (b === RedactionStampSelector.NO_STAMP_OPTION) {
                    return 1;
                }
                if (a === RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION) {
                    return -1;
                }
                if (b === RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION) {
                    return 1;
                }
                if (a.isRecent && b.isRecent) {
                    return recentStamps.indexOf(a.data.id) - recentStamps.indexOf(b.data.id);
                }
                if (a.isRecent) {
                    return -1;
                }
                if (b.isRecent) {
                    return 1;
                }
                if (a.isCustom !== b.isCustom) {
                    return a.isCustom ? 1 : -1;
                }
                return Cmp.str(a.data.display(), b.data.display());
            },
            popup: "after",
            // Set a custom zIndex so the dropdown appears on top of any dialogs but below its
            // mirror tooltips. Dijit dialogs use z-indexes starting at 1000 and incrementing as
            // dialogs are nested. The dropdown's Dijit tooltips are defaulting to z-index of 2000
            zIndex: "1999",
            maxRenderedElements: 100,
        };
    }

    /**
     * Updates the selector to reflect the current available stamps and the current state of
     * the stampable this selector operates on.
     */
    updateStampSelect(
        stampable?: RedactionStamp.SingleStampable | RedactionStamp.MultiStampable,
    ): void {
        if (stampable) {
            this.stampable = stampable;
        }
        if (this.stampSelector) {
            destroy(this.stampSelector);
            delete this.stampSelector;
        }
        Dom.hide(this.customWrapper);
    }

    destroy(): void {
        destroy(this.toDestroy);
        destroy(this.stampSelector);
    }
}

export class SingleRedactionStampSelector extends RedactionStampSelector {
    private onStampSelect: (stamp: RedactionStamp) => void;
    override stampable?: RedactionStamp.SingleStampable;
    override stampSelector?: SingleSelect<StampSelectItem>;

    constructor(params: SingleStampSelectorParams) {
        super(params);
        Dom.addClass(this.node, "redaction-stamper--single");
    }

    override initializeTitleAndRemover(): void {
        if (!this.title) {
            this.title = "Redaction stamp";
        }
        this.remover = Dom.div(
            {
                class: "redaction-stamper__link redaction-stamper__remover",
            },
            "Remove",
        );
    }

    override updateStampSelect(stampable?: RedactionStamp.SingleStampable): void {
        super.updateStampSelect(stampable);

        const currentStamp =
            (this.stampable && this.stampable.redactionStamp)
            || RedactionStampUtil.getDefaultStamp();
        const projectStamps = RedactionStampUtil.getProjectStamps();
        const allowsCustom = RedactionStampUtil.getAllowCustom();
        const customStamps = RedactionStampUtil.getCustomStamps();

        // If there are no project stamps, no custom stamps, and we don't allow custom stamps,
        // every redaction has no stamp meaning we don't need to show the selector.
        if (!(projectStamps.length || customStamps.length || currentStamp || allowsCustom)) {
            Dom.hide(this.remover);
            Dom.hide(this);
            return;
        }

        this.stampToSelectItems = RedactionStampSelector.getStampToSelectItems(
            projectStamps,
            customStamps,
            currentStamp.equals(RedactionStamp.NO_STAMP) ? [] : [currentStamp],
            this.allowsNoStamp,
        );
        const stampItems = this.getStampItems(allowsCustom);
        const toSelect = this.stampToSelectItems.get(currentStamp);
        const sharedStampSelectorParams = RedactionStampSelector.getStampSelectorParams();

        this.stampSelector = new SingleSelect<StampSelectItem>({
            ...sharedStampSelectorParams,
            elements: stampItems,
            // Recent stamps are inserted last when building the stamp select items, so this will
            // select the project/custom stamp select item over a matching recent stamp select item.
            initialSelected: toSelect ? toSelect[0] : undefined,
            onSelect: (e: StampSelectItem) => {
                if (!this.stampSelector) {
                    return;
                }
                const isCustom = e === RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION;
                isCustom && this.isMinimal && Dom.hide(this.stampSelector);
                Dom.show(this.customWrapper, isCustom);
                if (!isCustom) {
                    RedactionStampUtil.updateRecentlyUsed(e.data.id);
                    this._onStampSelect(e.data);
                } else {
                    this.customCreator.focus();
                }
                this.stampSelector.minimize();
            },
        });
        this.stampSelector.hideHeader("");
        Dom.place(this.stampSelector, this.selectNode);
        this.stampSelector.setDisabled(this.isDisabled);
        Dom.show(
            this.remover,
            this.stampSelector.initialSelected !== RedactionStampSelector.NO_STAMP_OPTION,
        );
    }

    private _onStampSelect(stamp: RedactionStamp) {
        Dom.hide(this.remover, stamp.equals(RedactionStamp.NO_STAMP));
        this.onStampSelect?.(stamp);
    }

    /**
     * Gets the stamp from the selector or creates it if it doesn't exist yet (such as if the
     * user has input a new custom stamp).
     * Gets default stamp if no stamp has been selected.
     */
    getOrCreateStamp(): Promise<RedactionStamp> {
        const defaultStamp = Promise.resolve(RedactionStampUtil.getDefaultStamp());
        if (!this.stampSelector) {
            return defaultStamp;
        }
        const val = this.stampSelector.getSelected();
        if (!val) {
            return defaultStamp;
        }
        if (val === RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION) {
            const curName = this.customCreator.getName();
            if (!Str.isNullOrWhitespace(curName)) {
                return this.addCustom();
            } else {
                return defaultStamp;
            }
        } else {
            return Promise.resolve(val.data);
        }
    }
}

export class MultiRedactionStampSelector extends RedactionStampSelector {
    // callback function which often should invoke getOrCreateSelectedStamps and use the resulting
    // promise in the callback. The callback can use the stamps parameter if it needs to, but if
    // the user has input a new custom stamp into the creator but hasn't clicked "Add" yet, that new
    // stamp will not be included in the stamps parameter.
    private onStampsChange: (stamps: RedactionStamp[]) => void;
    override stampable?: RedactionStamp.MultiStampable;
    override stampSelector?: MultiSelect<StampSelectItem>;
    private selectedStampChips?: ReactWidget<RedactionStampChipsProps>;

    constructor(params: MultiStampSelectorParams) {
        super(params);
        Dom.addClass(this.node, "redaction-stamper--multi");
    }

    override initializeTitleAndRemover(): void {
        if (!this.title) {
            this.title = "Redaction stamps";
        }
        this.remover = Dom.div(
            {
                class: "redaction-stamper__link redaction-stamper__remover",
            },
            "Remove all",
        );
    }

    override addCustom(): Promise<RedactionStamp> {
        this.stampSelector?.unselect(RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION);
        return super.addCustom();
    }

    override updateStampSelect(stampable?: RedactionStamp.MultiStampable): void {
        super.updateStampSelect(stampable);
        if (this.selectedStampChips) {
            destroy(this.selectedStampChips);
            delete this.selectedStampChips;
        }

        const redactionHasStamp =
            !!this.stampable?.redactionStamps && this.stampable?.redactionStamps.length > 0;
        const currentStamps =
            (this.stampable && this.stampable.redactionStamps)
            || RedactionStampUtil.getDefaultStamps();

        const projectStamps = RedactionStampUtil.getProjectStamps();
        const allowsCustom = RedactionStampUtil.getAllowCustom();
        const customStamps = RedactionStampUtil.getCustomStamps();

        // If there are no project stamps, no custom stamps, and we don't allow custom stamps,
        // every redaction has no stamp meaning we don't need to show the selector.
        if (!(projectStamps.length || customStamps.length || redactionHasStamp || allowsCustom)) {
            Dom.hide(this.remover);
            Dom.hide(this);
            return;
        }

        this.stampToSelectItems = RedactionStampSelector.getStampToSelectItems(
            projectStamps,
            customStamps,
            currentStamps,
            this.allowsNoStamp,
        );
        const stampItems = this.getStampItems(allowsCustom);
        const toSelect =
            currentStamps.length === 0
                ? this.allowsNoStamp
                    ? [RedactionStampSelector.NO_STAMP_OPTION]
                    : []
                : currentStamps.flatMap((s) => this.stampToSelectItems.get(s) || []);
        const sharedStampSelectorParams = RedactionStampSelector.getStampSelectorParams();

        this.stampSelector = new MultiSelect<StampSelectItem>({
            ...sharedStampSelectorParams,
            elements: stampItems,
            initialSelected: toSelect,
            placeholder: "Enter stamp name",
            textBoxParams: {
                value: this.getSelectedStampsSummary(currentStamps),
                onBlur: () => {
                    this.stampSelector?.tb.setValue(
                        this.getSelectedStampsSummary(this.getSelectedStamps()),
                    );
                    this.stampSelector?.filter("");
                },
                onFocus: () => {
                    // The use of setTimeout here is a hack to trigger text selection
                    // after the onClick event. If that order is reversed, text is
                    // selected and then immediately unselected by the click event.
                    setTimeout(() => {
                        this.stampSelector?.tb.select();
                    }, 0);
                },
            },
            onChange: (
                e: StampSelectItem | string,
                wasAdded: boolean,
                allSelected: StampSelectItem | { [className: string]: StampSelectItem[] },
            ) => {
                // e should always be a StampSelectItem
                if (!(e instanceof StampSelectItem) || !this.stampSelector) {
                    return;
                }
                // also select/unselect any other options that correspond to the same stamp (there
                // may be multiple options for one stamp because of the "Recently used" section)
                if (
                    e !== RedactionStampSelector.NO_STAMP_OPTION
                    && e !== RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION
                ) {
                    for (const matchingOption of this.stampToSelectItems.get(e.data) || []) {
                        if (wasAdded) {
                            this.stampSelector.select(matchingOption, true);
                        } else {
                            this.stampSelector.unselect(matchingOption, true);
                        }
                    }
                }
                const isCustom = e === RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION;
                if (isCustom) {
                    Dom.show(this.customWrapper, wasAdded);
                }
                if (wasAdded) {
                    if (e === RedactionStampSelector.NO_STAMP_OPTION) {
                        this.stampSelector.unselectAll(true);
                        this.stampSelector.select(e, true);
                    } else {
                        this.stampSelector.unselect(RedactionStampSelector.NO_STAMP_OPTION);
                    }

                    if (isCustom) {
                        this.customCreator.focus();
                        this.stampSelector.minimize();
                    } else {
                        RedactionStampUtil.updateRecentlyUsed(e.data.id);
                    }
                }
                if (this.stampSelector.getSelected().length === 0) {
                    this.stampSelector.select(RedactionStampSelector.NO_STAMP_OPTION, true);
                }
                this._onStampsChange(this.getSelectedStamps());
            },
        });

        if (!this.isMinimal) {
            this.selectedStampChips = wrapReactComponent(RedactionStampChips, {
                stamps: RedactionStampUtil.getAlphabetizedStamps(currentStamps),
                onClickX: (stamp: RedactionStamp) => {
                    const selectItems = this.stampToSelectItems.get(stamp);
                    if (selectItems?.length && selectItems.length > 0) {
                        this.stampSelector?.unselect(selectItems[0]);
                    }
                },
                disabled: this.isDisabled,
                onClickViewMore: () => {
                    this.stampSelector?.focus();
                },
            });
            Dom.place(this.selectedStampChips, this.selectNode);
        }

        this.stampSelector.hideHeader("");
        this.stampSelector.setElemDisabled(
            RedactionStampSelector.NO_STAMP_OPTION,
            currentStamps.length === 0,
        );
        Dom.place(this.stampSelector, this.selectNode);
        this.stampSelector.setDisabled(this.isDisabled);
        if (this.isDisabled) {
            this.toDestroy.push(
                new Tooltip(
                    this.stampSelector.getNode(),
                    "You do not have permission to edit stamps for this redaction",
                ),
            );
        }
        Dom.show(this.remover, currentStamps.length > 0);
    }

    private getSelectedStampsSummary(stamps: RedactionStamp[]) {
        return stamps.length > 1
            ? `(${stamps.length} stamps selected)`
            : stamps.length === 1
              ? stamps[0].display()
              : "(No stamp)";
    }

    private _onStampsChange(stamps: RedactionStamp[]) {
        Dom.hide(this.remover, stamps.length === 0);
        this.stampSelector?.setElemDisabled(
            RedactionStampSelector.NO_STAMP_OPTION,
            stamps.length === 0,
        );
        this.stampSelector?.tb.setValue(this.getSelectedStampsSummary(stamps));
        // to avoid filtering on the stamp summary text value
        this.stampSelector?.filter("");
        this.selectedStampChips?.updateProps({
            stamps: RedactionStampUtil.getAlphabetizedStamps(stamps),
        });
        this.onStampsChange?.(stamps);
    }

    /**
     * Gets array of currently selected stamps from the multi-stamp selector (or the default stamp
     * if the multi-stamp selector has not been initialized). If the user has input a new custom
     * stamp into the creator but hasn't clicked "Add" yet, that new stamp will not be included
     * here.
     */
    getSelectedStamps(): RedactionStamp[] {
        if (!this.stampSelector) {
            return RedactionStampUtil.getDefaultStamps();
        }
        const selectedStampItems = this.stampSelector.getSelected();
        const selectedStamps: RedactionStamp[] = [];
        if (selectedStampItems.includes(RedactionStampSelector.NO_STAMP_OPTION)) {
            return selectedStamps;
        }
        for (const stampItem of selectedStampItems) {
            if (
                !stampItem.isRecent
                && stampItem !== RedactionStampSelector.NO_STAMP_OPTION
                && stampItem !== RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION
            ) {
                selectedStamps.push(stampItem.data);
            }
        }
        return selectedStamps;
    }

    /**
     * Gets array of selected stamps from the multi-stamp selector, creating a new stamp if the user
     * has input a new custom stamp into the creator. Gets default stamp if the multi-stamp selector
     * has not been initialized.
     */
    async getOrCreateSelectedStamps(): Promise<RedactionStamp[]> {
        if (!this.stampSelector) {
            return Promise.resolve(RedactionStampUtil.getDefaultStamps());
        }
        const selectedStampItems = this.stampSelector.getSelected();
        const selectedStamps = this.getSelectedStamps();
        if (selectedStampItems.includes(RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION)) {
            const customName = this.customCreator.getName();
            if (!Str.isNullOrWhitespace(customName)) {
                const newStamp = await this.addCustom();
                selectedStamps.push(newStamp);
                return selectedStamps;
            }
        }
        return Promise.resolve(selectedStamps);
    }

    setStampSelectToNone(): void {
        this.updateStampSelect({ redactionStamps: [] });
    }

    stampSelectionIsChanged(otherStamps: RedactionStamp[]): boolean {
        const selectedStamps = this.getSelectedStamps();
        return (
            !(
                selectedStamps.length === otherStamps.length
                && selectedStamps.every((stamp) => otherStamps.includes(stamp))
            )
            || (!!this.stampSelector
                && this.stampSelector
                    .getSelected()
                    .includes(RedactionStampSelector.NEW_CUSTOM_STAMP_OPTION))
        );
    }

    override destroy(): void {
        super.destroy();
        destroy(this.selectedStampChips);
    }
}
