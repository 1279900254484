import Base = require("Everlaw/Base");
import Argument = require("Everlaw/Argument");
import { Document as ArgDoc } from "Everlaw/Argument/Document";
import { Arr } from "core";
import Chronology = require("Everlaw/Chron/Chronology");
import ChronProfile = require("Everlaw/Chron/ChronologyProfile");
import { PubSubChannel } from "Everlaw/PubSubChannel";
import DateUtil = require("Everlaw/DateUtil");
import DepoPageDefs = require("Everlaw/Depo/DepositionPageDefs");
import Perm = require("Everlaw/PermissionStrings");
import Project = require("Everlaw/Project");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import User = require("Everlaw/User");
import { UserObject } from "Everlaw/UserObject";

class Deposition extends UserObject implements Chronology.ChronObject {
    override id: Deposition.Id;
    argumentId: Argument.Id;
    summaryArgumentId: Argument.Id;
    profileId: ChronProfile.Id;
    timeScheduled: number = null;
    scheduledPrecision: number = null;
    timezone: string = null;
    location: string = null;
    citationFormat: string = null;
    state: Deposition.State;
    creatorId: User.Id;
    transcriptVersion: number;
    transcriptMediaVersion: number;
    transcriptVersionForCurMedia: number;
    /** The number of cover pages for the deposition's related transcript.  Defaults to 0. */
    transcriptCoverPageCount: number = 0;
    /**
     * The starting page number of the deposition's related transcript, after cover pages. Defaults to 1.
     * For example, if a transcript has multiple volumes, the second volume might start at page 101.
     */
    transcriptLogicalStartPageNumber: number = 1;

    name: string; // This is only used on the message page
    private argument: Argument;
    private summaryArgument: Argument;
    chronology: Chronology;

    constructor(params: any) {
        super(params);
        this._mixin(params);
        if (this.chronology) {
            if (!(this.chronology.id in Deposition.chronIdToDepos)) {
                Deposition.chronIdToDepos[this.chronology.id] = [];
            }
            Deposition.chronIdToDepos[this.chronology.id].push(this);
        }
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        if (params.state) {
            this.state = Deposition.State.byString(params.state);
        }
        // NOTE: We lazily fetch the arguments because depo creation updates could potentially
        // arrive before arg creation updates.
        this.chronology = Base.get(Chronology, params.chronologyId);
    }
    get className() {
        return "Deposition";
    }
    displayForCitation() {
        return this.citationFormat || this.display();
    }
    override display() {
        if (this.name) {
            // This shortcut is only used on the message page.
            return this.name;
        }
        const arg = this.getArgument();
        return arg ? arg.display() : "[Unknown deposition]";
    }
    isTranscriptOnly(): boolean {
        return !User.me.can(Perm.READ, this, User.Override.ELEVATED_OR_ORGADMIN);
    }
    getColor() {
        return Argument.DEPOSITION_COLOR;
    }
    getChronId() {
        return this.chronology ? this.chronology.id : null;
    }
    rename(newName: string): Promise<string> {
        const arg = this.getArgument();
        if (arg) {
            return arg.rename(newName);
        } else {
            return Promise.reject(new Rest.Failed(null, "Invalid request", 0));
        }
    }
    getArgument() {
        if (!this.argument) {
            this.argument = Base.get(Argument, this.argumentId);
        }
        return this.argument;
    }
    getDeponentProfile() {
        return this.profileId ? Base.get(ChronProfile, this.profileId) : null;
    }
    getSummaryArgument() {
        if (!this.summaryArgument) {
            this.summaryArgument = Base.get(Argument, this.summaryArgumentId);
        }
        return this.summaryArgument;
    }
    getTimezone() {
        return (this.timezone
            || (Project.CURRENT ? Project.CURRENT.timezoneId : null)) as DateUtil.TimezoneN;
    }
    /**
     * transcriptVersion === 0 (NO_FILE_VERSION) means the deposition does not have a transcript.
     * transcriptVersion < 0 means the deposition has a transcript currently being uploaded.
     * transcriptVersion > 0 means the deposition has a transcript ready to be displayed.
     */
    hasTranscript(): boolean {
        return Deposition.hasTranscript(this.transcriptVersion);
    }
    isTranscriptUploading(): boolean {
        return this.transcriptVersion < Deposition.NO_FILE_VERSION;
    }
    /**
     * transcriptMediaVersion === 0 (NO_FILE_VERSION) means this depo never had any media.
     * transcriptMediaVersion < 0 means this depo has had some media but user removed it.
     * transcriptMediaVersion > 0 means this depo has a transcript media ready to be displayed.
     */
    hasMedia(): boolean {
        return (
            this.transcriptMediaVersion > Deposition.NO_FILE_VERSION
            && this.transcriptVersionForCurMedia === this.transcriptVersion
        );
    }
    delete(callback?: () => void) {
        QueryDialog.create({
            prompt:
                `Are you sure you want to delete deposition "${this.display()}"? `
                + "All associated work product, including chat messages, exhibit links, summary, "
                + "and transcript will be deleted from the project.",
            submitText: "Delete",
            cancelText: "Cancel",
            submitIsSafe: false,
            title: "Delete deposition",
            onSubmit: () => {
                Rest.post(Project.CURRENT.url("deposition/remove.rest"), {
                    depositionId: this.id,
                }).then(() => {
                    // Don't Base.remove the depo here as we want to make sure any multiplex
                    // notifications have it available for cleanup. On pages without notifications,
                    // the callback should call Deposition.removeDepo() to clean up properly.
                    callback && callback();
                });
                return true;
            },
        });
        return true;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Deposition {
    export const exhibitNameUpdatedChannel = new PubSubChannel<ArgDoc>();

    export const chronIdToDepos: { [chronId: number]: Deposition[] } = {};

    export type Id = number & Base.Id<"Deposition">;

    export class State extends Base.Object {
        get className() {
            return "DepositionState";
        }
        constructor(
            id: string,
            public name: string,
            public fspIndex: number,
        ) {
            super({ id });
        }
    }

    export interface RestData {
        id: Deposition.Id;
        chronologyId: Chronology.Id;
        creatorId: User.Id;
        argumentId: Argument.Id;
        summaryArgumentId: Argument.Id;
        profileId: ChronProfile.Id;
        timeScheduled: number;
        scheduledPrecision: number;
        timezone: string;
        location: string;
        citationFormat: string;
        state: Deposition.State;
        transcriptVersion: number;
        transcriptCoverPageCount?: number;
        transcriptLogicalStartPageNumber?: number;
    }

    export module State {
        export function byString(str: string) {
            return Base.get(State, str);
        }

        export const DEPOSITION = new State("DEPOSITION", "Prep", 0);
        export const SUMMARY = new State("SUMMARY", "Summary", 1);
        export const TRANSCRIPT = new State("TRANSCRIPT", "Transcript", 2);
        export const all = [DEPOSITION, SUMMARY, TRANSCRIPT];
        all.forEach(Base.add);
    }

    export function createNew() {
        window.location.assign(DepoPageDefs.buildCreateDepoUrl());
    }

    export function goToDeposition(params: DepoPageDefs.DepoUrlParams, newWindow: boolean) {
        const url = DepoPageDefs.buildDepoUrl(params);
        if (newWindow) {
            window.open(url);
        } else {
            window.location.assign(url);
        }
    }

    export function removeDepo(depo: Deposition, includeArg = false) {
        Arr.remove(Deposition.chronIdToDepos[depo.chronology.id], depo);
        if (includeArg) {
            const arg = Base.get(Argument, depo.argumentId);
            arg && Base.remove(arg);
        }
        Base.remove(depo);
    }

    export function getDeposByChronId(chronId: Chronology.Id) {
        return Deposition.chronIdToDepos[chronId] || [];
    }

    export function getDepoByArgId(argId: Argument.Id) {
        return Arr.firstElement(Base.get(Deposition), (depo) => depo.argumentId === argId);
    }

    export const NO_FILE_VERSION = 0;

    /**
     * transcriptVersion === 0 (NO_FILE_VERSION) means the deposition does not have a transcript.
     * transcriptVersion < 0 means the deposition has a transcript currently being uploaded.
     * transcriptVersion > 0 means the deposition has a transcript ready to be displayed.
     */
    export function hasTranscript(transcriptVersion): boolean {
        return transcriptVersion > NO_FILE_VERSION;
    }
}

export = Deposition;
