import { Str } from "core";
import Base = require("Everlaw/Base");
import BasicRadio = require("Everlaw/UI/BasicRadio");
import Document = require("Everlaw/Document");
import Dom = require("Everlaw/Dom");
import NewMessage = require("Everlaw/Messaging/NewMessage");
import {
    getDefaultShareSubject,
    getShareDialogSubmitText,
    getShareDialogTitle,
} from "Everlaw/Messaging/MessageComposer";
import {
    NewSearchMessage,
    NewSearchComposer,
    NewSearchMessageParams,
    searchMessageComposer,
} from "Everlaw/Messaging/NewSearchMessage";
import { Recipient } from "Everlaw/Recipient";
import Rest = require("Everlaw/Rest");
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");

function getDefaultSelectedShareSubject(numSelected: number): string {
    const displayNumber =
        numSelected >= 1000 && numSelected % 1000 > 0
            ? "~" + Util.displayNumberAbbr(numSelected)
            : Util.displayNumberAbbr(numSelected);

    return `${User.me.display()} has shared ${displayNumber} Documents with you`;
}

function getDefaultSelectedShareTitle(docIds: number[], fromAll: boolean, numSelected: number) {
    let batesNumbers = [];

    if (!fromAll) {
        batesNumbers = docIds
            .map((id) => Base.get(Document, id)?.beginBates)
            // filter out potential null values
            .filter((str) => str);
    } else {
        const docIdSet = new Set(docIds);
        batesNumbers = Base.get(Document)
            .filter((doc) => !docIdSet.has(doc.id))
            .map((doc) => doc?.beginBates)
            // filter out potential null values
            .filter((str) => str);
    }

    /*
    "or..." case is when the docs in searchDocStore aren't all the docs being shared (we can't fetch them all
    for performance reasons).
     */
    const allDocsFetched = batesNumbers.length >= numSelected;
    const wouldOverflow = (str: string) => str.length > 135;

    let batesString = allDocsFetched
        ? Str.arrayToStringList(batesNumbers, "or")
        : `Bates ${batesNumbers.join(", ")} or...`;

    // If the current batesString would ~overflow tooltip, ellipsize the bates numbers.
    if (wouldOverflow(batesString)) {
        const ellipsizedBates = batesNumbers.map((batesNum) => batesNum.split(".")[0]);
        batesString = allDocsFetched
            ? Str.arrayToStringList(ellipsizedBates, "or")
            : `Bates ${ellipsizedBates.join(", ")} or...`;

        // If ellipsized bates would still overflow, modify string to end with "or ..."
        if (wouldOverflow(batesString)) {
            const lastCommaToInclude = batesString.slice(0, 130).lastIndexOf(",");
            batesString = batesString.slice(0, lastCommaToInclude + 1) + " or...";
        }
    }

    return batesString;
}

/**
 * Use this method for shared objects to generate a default subject line.
 */
export function composeShareableSelectedSearch(
    messageParams: NewSelectedSearchMessageParams,
    composerParams: NewSelectedSearchComposerParams = {},
): Promise<NewMessage.Composer | null> {
    messageParams.subject = getDefaultSelectedShareSubject(composerParams.numActive);
    composerParams.subsetName = getDefaultSelectedShareTitle(
        messageParams.docIds,
        messageParams.fromAll,
        composerParams.numActive,
    );

    composerParams.dialogTitle = getShareDialogTitle(messageParams.attachment);
    composerParams.submitText = getShareDialogSubmitText();

    return searchMessageComposer(
        messageParams,
        composerParams,
        NewSelectedSearchMessage,
        NewSelectedSearchComposer,
    );
}

export interface NewSelectedSearchMessageParams extends NewSearchMessageParams {
    fromAll: boolean;
    docIds: number[];
}

export class NewSelectedSearchMessage extends NewSearchMessage {
    fromAll: boolean;
    docIds: number[];
    shareOptionSelect: BasicRadio;
    constructor(params: NewSelectedSearchMessageParams) {
        super(params);
        this.fromAll = params.fromAll;
        this.docIds = params.docIds;
    }

    override canReceiveAttachment(recipient: Recipient): boolean {
        // On creation of the message, this is fired before shareOptionSelect is instantiated.
        // The default shareOption is "subset", so we want the first return statement to be hit.
        if (!this.shareOptionSelect || this.isSubsetSearch()) {
            return !this.searchObjectPermission.shouldLockRecipient(recipient, true);
        } else {
            return !this.searchObjectPermission.shouldLockRecipient(recipient);
        }
    }

    protected override _doSend(params: NewMessage.SendParams) {
        if (!this.isSubsetSearch()) {
            super._doSend(params);
        } else {
            Rest.post("messages/constructAndShareSearch.rest", {
                replyTo: this.inReplyTo && this.inReplyTo.id,
                recipients: params.recipients,
                text: params.text,
                subject: params.subject,
                attachmentId: this.attachment.id,
                attachmentClass: this.attachment.className,
                attachmentPerm: params.attachmentPerm,
                fromAll: this.fromAll,
                docIds: this.docIds,
            }).then((data) => this._handleDataSuccess(data));
        }
    }

    isSubsetSearch(): boolean {
        return this.shareOptionSelect.getSelectedId() === "subsetSearch";
    }
}

export interface NewSelectedSearchComposerParams extends NewMessage.ComposerParams {
    // Number of selected documents
    numActive?: number;
    // Used when sharing specific documents
    subsetName?: string;
}

export class NewSelectedSearchComposer extends NewSearchComposer {
    private static readonly MAX_SEARCH_SIZE = 10000;
    private shareOptionSelect: BasicRadio;
    private numSelected: number;
    private isRestrictedSender: boolean;
    private subsetName: string;

    constructor(
        public override message: NewSelectedSearchMessage,
        params: NewSelectedSearchComposerParams,
    ) {
        super(message, params);
        this.subsetName = params.subsetName;
        this.numSelected = params.numActive;
        this.buildShareOptionContainer();
        this.hideAttachmentWarning();
        Dom.setContent(this.messageAttachmentName, this.subsetName);
        this.isRestrictedSender =
            !!this.message.searchObjectPermission.getShareableObjectsSenderCannotShare();
        this.checkForValidSearch();
    }

    protected buildShareOptionContainer(): void {
        const displayNumber =
            this.numSelected >= 1000 && this.numSelected % 1000 > 0
                ? "~" + Util.displayNumberAbbr(this.numSelected)
                : Util.displayNumberAbbr(this.numSelected);
        const shareOptions = [
            {
                id: "subsetSearch",
                display: `Share ${displayNumber} selected documents`,
                leftMargin: "10px",
                disabledTooltip:
                    "The number of selected documents is too large to be shared via this option",
            },
            {
                id: "fullSearch",
                display: "Share the entire search results table",
                leftMargin: "10px",
            },
        ];
        this.shareOptionSelect = new BasicRadio(shareOptions, false);
        this.shareOptionSelect.select("subsetSearch");
        this.shareOptionSelect.onChange = (selected) => {
            let subjectName: string;
            if (selected.id === "subsetSearch") {
                subjectName = getDefaultSelectedShareSubject(this.numSelected);
                Dom.setContent(this.messageAttachmentName, this.subsetName);
                this.hideAttachmentWarning();
                this.revertShareButtonAndPermWarning();
            } else {
                subjectName = getDefaultShareSubject(this.message.attachment);
                Dom.setContent(this.messageAttachmentName, this.message.attachment.display());
                this.showAttachmentWarning(this.buildAttachmentWarningMessage());
                this.updateSearchObjectSharing();
                this.onObjGrantingChange();
            }
            this.subjectWidget.setValue(subjectName);
            Dom.setContent(this.subjectEditorHeader, subjectName);
            // Only rebuild the widget in the case that the sender has shareable objects that
            // cannot be shared. Otherwise, the recipients widget remains the same between options.
            if (this.isRestrictedSender) {
                this.buildRecipientsWidget();
            }
        };

        this.toDestroy.push(this.shareOptionSelect);

        const searchOptions = Dom.div(
            { class: "title-and-options-container" },
            Dom.span({ class: "share-option-container__label" }, "Share options"),
            Dom.div(
                { class: "search-options-and-view-container" },
                Dom.div({ class: "share-options-container" }, this.shareOptionSelect.getNode()),
            ),
        );
        Dom.place(searchOptions, this.subjectContainer, "before");
        this.message.shareOptionSelect = this.shareOptionSelect;
    }

    protected hideAttachmentWarning(): void {
        Dom.hide(this.attachmentWarningContainer);
        Dom.hide(this.nonAttachmentRecipientsDiv);
    }

    protected checkForValidSearch(): void {
        if (this.numSelected < NewSelectedSearchComposer.MAX_SEARCH_SIZE) {
            return;
        } else {
            this.shareOptionSelect.select("fullSearch");
            this.shareOptionSelect.setDisabled("subsetSearch", true);
            this.shareOptionSelect.onChange(
                this.shareOptionSelect.elements[1],
                this.shareOptionSelect.elements[0],
            );
        }
    }

    protected override setExtraSendParams(params: NewMessage.SendParams) {
        super.setExtraSendParams(params);
        params.shareSubset = this.shareOptionSelect.getSelectedId() === "subsetSearch";
        params.subsetName = this.subsetName;
        return params;
    }

    protected override onRecipientChange(recipient: Recipient, added: boolean) {
        const changeShareButton = this.shareOptionSelect.getSelectedId() === "fullSearch";
        super.onRecipientChange(recipient, added, changeShareButton);
        if (
            this.message.nonAttachmentRecipients.size
            && this.shareOptionSelect.getSelectedId() === "fullSearch"
        ) {
            Dom.show(this.attachmentWarningContainer);
        } else if (
            !this.message.nonAttachmentRecipients.size
            && !this.attachmentWarningMsgDiv.textContent
        ) {
            Dom.hide(this.attachmentWarningContainer);
        }
    }

    protected override updateSearchObjectSharing(): void {
        if (!this.shareOptionSelect) {
            Dom.hide(this.searchObjectSharingContainer);
        } else if (this.shareOptionSelect.getSelectedId() === "fullSearch") {
            super.updateSearchObjectSharing();
        }
    }

    protected revertShareButtonAndPermWarning(): void {
        this.dialog._submitButton.setContent("Share");
        Dom.replaceClass(this.dialog._submitButton, "one-width", "obj-perm-grant-button");
        Dom.hide(this.searchObjectSharingContainer);
    }
}
