import { ObjectListData, StringData } from "Everlaw/Review/ChatViewer/Redaction/ChatRedactionData";

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.ChatSegmentMap`.
 */
export type SegmentMap = {
    participants: ObjectListData<ParticipantMap>[];
    events: ObjectListData<EventMap>[];
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.ParticipantMap`.
 */
export type ParticipantMap = {
    id?: StringData;
    name?: StringData;
    phone?: StringData;
    email?: StringData;
    username?: StringData;
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.EventMap`.
 */
export type EventMap = {
    blocks: ObjectListData<BlockMap>[];
    edits: ObjectListData<EditMap>[];
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.EventMap.BlockMap`.
 */
export type BlockMap = {
    text?: StringData;
};

/**
 * Ported from `everlaw.model.chatviewer.redaction.mappings.EventMap.EditMap`.
 */
export type EditMap = {
    blocks: ObjectListData<BlockMap>[];
};

/**
 * Create a redaction mapping representing a full redaction on a chat event.
 */
export function createFullEventRedactionMapping(eventIndex: number): SegmentMap {
    const eventData: ObjectListData<EventMap> = { fullyRedacted: true, index: eventIndex };
    return { participants: [], events: [eventData] };
}

/**
 * If the given mapping represents an event redaction, fetch the index of that event within its
 * segment.
 */
export function getEventIndex(segmentMap: SegmentMap): number | undefined {
    return segmentMap.events[0]?.index;
}

/**
 * If the given mapping represents an event redaction, is it a full redaction?
 */
export function isFullEventRedaction(segmentMap: SegmentMap): boolean {
    return !!segmentMap.events[0]?.fullyRedacted;
}
