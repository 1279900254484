import * as Base from "Everlaw/Base";
import { Arr, Compare as Cmp, Is } from "core";
import Document from "Everlaw/Document";
import { elevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";
import { NoteUtil } from "Everlaw/Note";
import { Redaction, canReadRedactions, canModifyOrDeleteRedaction } from "Everlaw/Redaction";
import * as Rest from "Everlaw/Rest";
import { TIME_NOT_SET } from "Everlaw/Review/MediaViewer/MediaPlayer";
import * as User from "Everlaw/User";

// If Redaction begins using typed params, this should extend it.
export interface MillisecondRange {
    startTime?: number; // ms
    endTime?: number; // ms
}

export interface RedactedRange extends MillisecondRange {
    // Used when flattening a group of potentially overlapping redactions into
    // contiguous, non-overlapping ranges
    redactions: MediaRedaction[];
}

export class MediaRedaction extends Redaction implements MillisecondRange {
    startTime: number;
    endTime: number;

    override _mixin(params: MillisecondRange): void {
        // If this has values but params does not, keep this
        // If params has values, use those
        // If neither has values, use TIME_NOT_SET
        let start;
        if (Is.defined(params.startTime)) {
            start = params.startTime;
            delete params.startTime;
        } else {
            start = this.startTime;
        }
        let end;
        if (Is.defined(params.endTime)) {
            end = params.endTime;
            delete params.endTime;
        } else {
            end = this.endTime;
        }
        if (
            Is.defined(this.startTime)
            && Is.defined(this.endTime)
            && (start !== this.startTime || end !== this.endTime)
        ) {
            Base.remove(this);
        }
        this.startTime = Is.defined(start) ? start : TIME_NOT_SET;
        delete params.startTime;
        this.endTime = Is.defined(end) ? end : TIME_NOT_SET;
        delete params.endTime;
        // Pass off to super for stamp, etc
        super._mixin(params);
        if (Is.defined(this.id)) {
            Base.add(this);
        }
    }

    get className(): string {
        return "MediaRedaction";
    }

    get redactionType(): NoteUtil.ParentType {
        return NoteUtil.ParentType.MediaRedaction;
    }

    /** A comparison function for use in sorting */
    override compare(other: MediaRedaction): number {
        return this.compareByTimes(other);
    }

    compareByTimes(other: MillisecondRange): number {
        return Cmp.num(this.startTime, other.startTime) || Cmp.num(this.endTime, other.endTime);
    }

    commit(): Promise<unknown> {
        MediaRedaction.cleanTimes(this);
        return Rest.post("documents/saveMediaRedaction.rest", {
            docId: this.docId,
            mediaRedactionId: this.id,
            stampIds: this.redactionStamps.map((stamp) => stamp.id),
            startTime: this.startTime,
            endTime: this.endTime,
        }).then((data) => {
            if (!Is.number(this.id)) {
                this.id = data.id;
                Base.add(this);
            }
            this._mixin(data);
            Base.publish(this);
        });
    }

    // eslint-disable-next-line new-cap
    @elevatedRoleConfirm("removing a media redaction")
    remove(callback?: (h: Redaction, msg?: string) => void, error?: Rest.Callback): void {
        Base.remove(this.notes);
        if (Is.number(this.id)) {
            Rest.post("documents/deleteMediaRedaction.rest", { mediaRedactionId: this.id }).then(
                (data) => {
                    Base.remove(this);
                    callback?.(this, data);
                },
                (e) => {
                    error?.(e);
                    throw e;
                },
            );
        } else {
            // This wasn't a saved redaction - we don't have to do any deleting,
            // but we should call the callback!
            callback?.(this);
        }
    }

    /** Does the given time in milliseconds fall within this redaction */
    contains(time: number): boolean {
        return MediaRedaction.rangeContains(this, time);
    }

    static rangeContains(range: MillisecondRange, time: number): boolean {
        time = Math.round(time);
        return (
            (range.startTime === TIME_NOT_SET || time >= range.startTime)
            && (range.endTime === TIME_NOT_SET || time <= range.endTime)
        );
    }

    /**
     * Ensure start and end times of the provided media redaction or similar object
     * have defined values, and values are whole milliseconds
     * */
    static cleanTimes(range: MillisecondRange): void {
        range.startTime =
            Is.defined(range.startTime) && Is.number(range.startTime)
                ? Math.round(range.startTime)
                : TIME_NOT_SET;
        range.endTime =
            Is.defined(range.endTime) && Is.number(range.endTime)
                ? Math.round(range.endTime)
                : TIME_NOT_SET;
    }

    /** Delete all media redactions for this document if uid is undefined.
     * If a non-null uid is passed, then only delete redactions created by the associated user. */
    static deleteByDocumentId(docId: number, uid?: number): void {
        this.batchDelete(
            docId,
            Base.get(MediaRedaction).filter((r) => !uid || (r.userId && r.userId === uid)),
        );
    }

    /**
     * Delete a group of redactions from a document
     * @param docId - Limit deletion to redactions associated with this document
     * @param redactions - Redactions from which to delete
     */
    static batchDelete(docId: number, redactions: MediaRedaction[]): void {
        if (!redactions?.length) {
            return;
        }
        Rest.post("documents/bulkMediaRedact.rest", {
            docId: docId,
            changes: JSON.stringify(
                redactions.filter((r) => r.docId === docId).map((r) => [r.id, null]),
            ),
        }).then(() => Base.remove(redactions));
    }

    /**
     *
     * @param docId - The document to which these redactions apply
     * @param redactions - An array of redactions to persist
     */
    static batchRedact(docId: number, redactions: MediaRedaction[]): void {
        // Send 0 if this is a new redaction, id if modifying existing
        // Note if modifying the user will be updated in the back end
        Rest.post("documents/bulkMediaRedact.rest", {
            docId: docId,
            changes: JSON.stringify(redactions.map((r) => [r.id || 0, r.stringifiableObject()])),
        });
    }
}

/**
 * MediaRedactionManager maintains a collection of media redactions and provides methods for
 * managing and searching that collection
 */
export class MediaRedactionManager extends Base.SimpleStore<MediaRedaction> {
    private lastCheck = { time: -1, index: -1 }; // Result of last request for matching time
    // A sorted list of current redactions rebuilt as needed from byId
    private redactions: MediaRedaction[] = null;
    // A sorted list of non-overlapping ranges, each with associated redactions, rebuilt as needed
    private contiguous: RedactedRange[] = null;
    private baseSubscription: { unsubscribe: () => void };
    private doc: Document;
    private filterOut = {}; // ids of MediaRedactions we don't want to include

    constructor() {
        super("MediaRedactionManager", false);
    }

    override add(r: MediaRedaction | MediaRedaction[]): void {
        if (!r) {
            return;
        }
        let added: MediaRedaction[] = [];
        const shouldAdd = (r: MediaRedaction): boolean => {
            // Should actually add it iff it matches the doc (if specified)
            // and it's not set to be filtered out
            return !((this.doc && r.docId !== this.doc.id) || this.filterOut[r.id]);
        };
        if (Is.array(r)) {
            added = r.filter((r) => shouldAdd(r));
        } else if (shouldAdd(r)) {
            added = [r];
        }
        if (added.length) {
            added.forEach((r) => super.add(r));
            this.resetCache();
            this.publish(added); // super.add does not publish
        }
    }

    override remove(r: MediaRedaction | MediaRedaction[] | string): void {
        if (Is.string(r)) {
            r = this.get(r);
        }

        if (!r) {
            return;
        }

        const removed: MediaRedaction[] = [];

        if (Is.array(r)) {
            for (const redaction of r) {
                const have = this.get(redaction.id);
                have && removed.push(have);
            }
        } else {
            const have = this.get(r.id);
            have && removed.push(have);
        }

        if (removed.length) {
            this.resetCache();
            super.remove(removed); // Will publish
        }
    }

    override getAll(): MediaRedaction[] {
        if (!this.redactions) {
            this.redactions = super.getAll().sort((a, b) => a.compare(b));
        }
        return this.redactions;
    }

    /**
     * Optionally set Document.
     * Redactions in this manager will be limited to those applied to that document
     * */
    setDoc(doc: Document): void {
        const removed: MediaRedaction[] = [];
        this.doc = doc;
        for (const r of this.getAll()) {
            if (r.docId !== this.doc.id) {
                removed.push(r);
            }
        }
        this.remove(removed);
        this.updateFromBase();
    }

    /** Optionally tell this manager to subscribe to the global base and keep itself current */
    setBaseSubscription(doSubscribe = true): void {
        this.baseSubscription?.unsubscribe();
        this.baseSubscription = null;

        if (!doSubscribe) {
            return;
        }

        if (canReadRedactions()) {
            this.baseSubscription = Base.subscribe(MediaRedaction, (r, deleted) => {
                if (deleted) {
                    this.remove(r);
                } else {
                    this.add(r);
                }
            });
            this.updateFromBase();
        }
    }

    /** If this manager is subscribed to the global base, get all redactions from that base */
    private updateFromBase(): void {
        if (!this.baseSubscription) {
            return;
        }
        this.add(Base.get(MediaRedaction));
    }

    /** Set the manager to exclude and remove or allow and add specific redaction */
    setFilter(r: MediaRedaction | string, toInclude = false): void {
        const id = Is.string(r) ? r : r.id;
        if (!id) {
            return;
        }
        !toInclude && this.get(id) && this.remove(this.get(id));
        this.filterOut[id] = !toInclude;
        if (toInclude) {
            this.add(Base.get(MediaRedaction, id));
        }
    }

    clearFilter(): void {
        this.filterOut = {};
        this.updateFromBase();
    }

    private resetCache(): void {
        this.redactions = null;
        this.contiguous = null;
        this.lastCheck.index = -1;
        this.lastCheck.time = -1;
    }

    /** Return all redactions in the manager which cover the given time (ms) */
    matchingRedactions(time: number): MediaRedaction[] {
        return this.matchingRangeRedactions({ startTime: time, endTime: time });
    }

    /**
     * Return list of redactions which contain both the provided start and end time (in ms)
     * @param range
     */
    matchingRangeRedactions(range: MillisecondRange): MediaRedaction[] {
        MediaRedaction.cleanTimes(range);
        const redactions = this.getAll();
        const result = [];
        // If they're checking some tick forward in time and the collection of redactions
        // hasn't changed, we can pick up where we left off
        let i =
            this.lastCheck.index > -1 && this.lastCheck.time <= range.startTime
                ? this.lastCheck.index
                : 0;
        for (; i < redactions.length; i++) {
            const next = redactions[i];
            if (next.startTime > range.endTime) {
                if (result.length === 0) {
                    this.lastCheck.index = i - 1;
                    this.lastCheck.time = range.endTime;
                }
                return result;
            }
            if (next.contains(range.startTime) && next.contains(range.endTime)) {
                if (result.length === 0) {
                    this.lastCheck.index = i;
                    this.lastCheck.time = range.startTime;
                }
                result.push(next);
            }
        }
        return result;
    }

    /**
     * Get either the full list of RedactedRange for this doc,
     * or provide a time in milliseconds and get a subset representing the contiguous stretch
     * of media which is redacted including that time
     * @param time: a time in milliseconds
     */
    getContiguous(time?: number): RedactedRange[] {
        if (!this.contiguous || !this.redactions) {
            // Be lazy about building this
            this.buildContiguous();
        }
        if (!time && time !== 0) {
            return this.contiguous;
        }
        time = Math.round(time);
        if (!this.contiguous.length || this.contiguous[this.contiguous.length - 1].endTime < time) {
            // time is after all regions
            return [];
        }
        // Find last region with start time before or equal to this time
        const i = findRange(this.contiguous, time, (r) => r.startTime);
        if (i < 0) {
            // This time is not in one of the regions
            return [];
        }
        // Does it match?
        if (this.contiguous[i].endTime < time) {
            return [];
        }
        // Region at index i is a match, does the redacted region extend back?
        let start = i;
        let next = i - 1;
        while (next >= 0 && this.contiguous[next].endTime === this.contiguous[start].startTime) {
            start = next;
            next--;
        }
        // Does it extend forward?
        let end = i;
        next = i + 1;
        while (
            next < this.contiguous.length
            && this.contiguous[next].startTime === this.contiguous[end].endTime
        ) {
            end = next;
            next++;
        }
        return this.contiguous.slice(start, end + 1);
    }

    private buildContiguous(): void {
        let toStart = false;
        let toEnd = false;
        this.contiguous = [];
        const times: number[] = []; // in ms
        this.getAll().forEach((r) => {
            if (r.startTime === TIME_NOT_SET) {
                toStart = true;
            } else {
                times.push(r.startTime);
            }
            if (r.endTime === TIME_NOT_SET) {
                toEnd = true;
            } else {
                times.push(r.endTime);
            }
        });
        toStart && times.push(0);
        toEnd && times.push(Number.MAX_VALUE);
        Arr.sort(times);
        for (let i = 0; i < times.length - 1; i++) {
            if (times[i] === times[i + 1]) {
                // skip any repeats
                continue;
            }
            const matchRedactions = this.matchingRangeRedactions({
                startTime: times[i],
                endTime: times[i + 1],
            });

            matchRedactions.length
                && this.contiguous.push({
                    startTime: times[i],
                    endTime: times[i + 1],
                    redactions: matchRedactions,
                });
        }
    }

    /**
     * Get the redactions in the manager which exactly match the given time range
     * with the user's own redactions first
     */
    getByTimes(times: MillisecondRange): MediaRedaction[] {
        const myRedaction = (r: MediaRedaction) => r.user === User.me || r.userId === User.me.id;
        return this.matchingRangeRedactions(times)
            .filter((r) => r.compareByTimes(times) === 0)
            .sort((a, b) => (myRedaction(a) ? 0 : 1) - (myRedaction(b) ? 0 : 1));
    }

    /**
     * Get the redactions in the manager which the user can edit or delete
     * and which exactly match the given time range
     * with the user's own redactions first
     */
    getEditableByTimes(times: MillisecondRange): MediaRedaction[] {
        return this.getByTimes(times).filter((r) => canModifyOrDeleteRedaction(r));
    }

    destroy(): void {
        this.resetCache();
        this.baseSubscription?.unsubscribe();
        this.doc = null;
        this.filterOut = {};
    }
}

/**
 * Given a list of ranges return the index of the object with smallest positive (value - rangeStart)
 * which is to say the range with rangeStart before but close to value
 * or -1 if time is before all objects in list
 * @param list - an array of ranges with a start, sorted by start
 * @param value - the time to search for in the ranges
 * @param rangeStart - a function which takes a range and returns its start
 */
export function findRange<T>(
    list: T[],
    value: number,
    rangeStart: (rangeElement: T) => number,
): number {
    const i = Arr.binarySearch(list, value, (v, range) => v - rangeStart(range));
    // If value does not coincide exactly with a range start,
    // return index of range with start closest to and before value
    return i < 0 ? -1 * i - 2 : i;
}
