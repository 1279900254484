import Base = require("Everlaw/Base");
import Dom = require("Everlaw/Dom");
import { InlineBanner, Icon as ReactIcon } from "design-system";
import { Note, NoteUtil } from "Everlaw/Note";
import { Is } from "core";
import RedactionStamp = require("Everlaw/Review/RedactionStamp");
import * as RedactionStampUtil from "Everlaw/Review/RedactionStampUtil";
import Rest = require("Everlaw/Rest");
import User = require("Everlaw/User");
import Perm = require("Everlaw/PermissionStrings");
import Project = require("Everlaw/Project");
import { Id } from "Everlaw/Document";
import { wrapReactComponent } from "Everlaw/UI/ReactWidget";
import { createElement } from "react";

export abstract class Redaction
    extends Base.SecuredObject
    implements RedactionStamp.MultiStampable
{
    abstract get redactionType(): NoteUtil.ParentType;
    redactionStamps: RedactionStamp[];
    docId: Id;
    user: User = null;
    created: number = null;
    updated: number = null;
    updaterId: User.Id = null;
    text: string = null;
    userId: User.Id = null;
    notes: Note[] = [];

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        if (Is.defined(params.notes)) {
            const paramNotes: any[] = params.notes;
            for (const i in paramNotes) {
                if (!(paramNotes[i] instanceof Note)) {
                    paramNotes[i] = new Note(paramNotes[i]);
                }
            }
        }
        if (params.userId) {
            this.userId = params.userId;
            this.user = Base.get(User, this.userId);
        }
        delete params.userId;

        if (params.redactionStampIds) {
            this.setStampsFromIds(params.redactionStampIds);
        } else if (params.redactionStamps) {
            this.redactionStamps = params.redactionStamps;
        } else {
            const defaultStamp = RedactionStampUtil.getDefaultStamp();
            this.redactionStamps = defaultStamp.equals(RedactionStamp.NO_STAMP)
                ? []
                : [defaultStamp];
            RedactionStampUtil.updateRecentlyUsed(defaultStamp.id);
        }
        delete params.redactionStamps;

        Object.assign(this, params);
    }

    // Add the given note to the redaction
    addNote(n: Note): void {
        n.parentType = this.redactionType;
        n.parentId = this.id;
        this.notes.push(n);
    }

    // Remove the note from the redaction, returning whether the note was in this.notes
    removeNote(n: Note): boolean {
        const prevLength = this.notes.length;
        this.notes = this.notes.filter((e) => e.id !== n.id);
        return this.notes.length !== prevLength;
    }

    // Update redaction edited information with given user and current time.
    updateEditor(updater: User.Id) {
        this.updaterId = updater;
        this.updated = Date.now();
    }

    committed() {
        return Is.number(this.id);
    }

    abstract commit(): Promise<unknown>;

    updateStamps(redactionStamps: RedactionStamp[]) {
        this.redactionStamps = redactionStamps;
        this.commit().then((data) => {
            this._mixin(data);
            Base.publish(this);
        });
    }

    setStampsFromIds(stampIds: number[]) {
        const redactionStamps = [];
        let needToFetchStamps = false;
        for (const stampId of stampIds) {
            const stamp = Base.get(RedactionStamp, stampId);
            if (stamp) {
                redactionStamps.push(stamp);
            } else {
                needToFetchStamps = true;
                break;
            }
        }
        // If a redaction has a stamp id that we can't find in the frontend, then we should mark it
        // as inUse and fetch it. This may occur if a user has their review window open during a
        // TransferWorkProductTask that creates and applies new project stamps.
        if (needToFetchStamps) {
            this.fetchAndMarkStamps(stampIds).then((stamps: RedactionStamp[]) => {
                this.redactionStamps = stamps;
                Base.publish(this);
            });
        } else {
            this.redactionStamps = redactionStamps;
        }
    }

    private async fetchAndMarkStamps(stampIds: number[]): Promise<RedactionStamp[]> {
        const stamps: RedactionStamp[] = await Rest.get("fetchAndMarkStamps.rest", {
            stampIds,
        });
        return Base.set(RedactionStamp, stamps);
    }

    stringifiableObject(): Record<string, unknown> {
        const json: Record<string, unknown> = {};
        for (const obj in this) {
            json[obj.toString()] = this[obj];
        }
        delete json["redactionStamps"];
        json["stampIds"] = this.redactionStamps.map((stamp) => stamp.id);
        return json;
    }
}

export function createRedactionStampPermissionWarning() {
    return wrapReactComponent(InlineBanner, {
        children: "You do not have permission to edit or delete this redaction",
        icon: createElement(ReactIcon.AlertTriangle, { size: 20, "aria-hidden": true }),
        className: "redaction-warning__text",
    });
}

export function createViewOnlyRedactionStampInfo(stamps: RedactionStamp[]): HTMLElement {
    const stampsDisplayText = RedactionStampUtil.getStampsContentAndAbbrDisplay(stamps);
    return Dom.div({ class: "redaction-panel__view-only-stamp-info" }, [
        Dom.div({ class: "notes-panel-title" }, "Redaction stamps"),
        Dom.div(
            { class: stampsDisplayText ? "" : "redaction-panel__view-only-stamp-text--no-stamps" },
            stampsDisplayText ? stampsDisplayText : "No stamps",
        ),
    ]);
}

export function canReadRedactions(): boolean {
    return User.me.can(Perm.READ_REDACTIONS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
}

export function canCreateRedactions(): boolean {
    return User.me.can(Perm.CREATE_REDACTIONS, Project.CURRENT, User.Override.ELEVATED);
}

export function canDeleteAllRedactions() {
    return User.me.can(Perm.ADMIN_REDACTIONS, Project.CURRENT, User.Override.ELEVATED);
}

export function canModifyOrDeleteRedaction(redaction: Redaction) {
    return (
        canDeleteAllRedactions()
        || (redaction.user === User.me
            && User.me.can(Perm.CREATE_REDACTIONS, Project.CURRENT, User.Override.ELEVATED))
    );
}
