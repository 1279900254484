import Base = require("Everlaw/Base");
import Project = require("Everlaw/Project");
import User = require("Everlaw/User");

interface RedactionStampParams {
    id?: number;
    content: string;
    abbreviation: string;
    projectId?: Project.Id;
    userId?: User.Id;
}

class RedactionStamp extends Base.Object {
    get className(): string {
        return "RedactionStamp";
    }

    private userId: User.Id;
    private content: string;
    private abbreviation: string;
    override id: number;

    constructor(params: RedactionStampParams) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: RedactionStampParams): void {
        Object.assign(this, params);
    }

    getContent(): string {
        return this.content;
    }

    getAbbreviation(): string {
        return this.abbreviation;
    }

    getUser(): User | null {
        return this.userId ? Base.get(User, this.userId) : null;
    }

    isProjectStamp(): boolean {
        return this.userId === 0;
    }

    override display(): string {
        let disp = this.getContent();
        const abbr = this.getAbbreviation();
        if (abbr) {
            disp += " (" + abbr + ")";
        }
        return disp;
    }

    override equals(other: RedactionStamp): boolean {
        if (!other) {
            return false;
        }
        return (
            other.getContent() === this.getContent()
            && other.getAbbreviation() === this.getAbbreviation()
        );
    }
}

module RedactionStamp {
    export interface SingleStampable {
        redactionStamp: RedactionStamp;
    }
    export interface MultiStampable {
        redactionStamps: RedactionStamp[];
    }

    // NO_STAMP is used in places where we expect either 0 or 1 stamps (e.g. as a user's default
    // stamp). The stamp id for NO_STAMP is undefined.
    export const NO_STAMP = new RedactionStamp({ content: "", abbreviation: "" });
}

export = RedactionStamp;
