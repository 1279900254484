import ActionNode = require("Everlaw/UI/ActionNode");
import array = require("dojo/_base/array");
import Base = require("Everlaw/Base");
import BaseObjectList = require("Everlaw/UI/BaseObjectList");
import BaseSelect = require("Everlaw/UI/BaseSelect");
import Bugsnag = require("Everlaw/Bugsnag");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import HomepageFolder = require("Everlaw/HomepageFolder");
import Icon = require("Everlaw/UI/Icon");
import { IconButton } from "Everlaw/UI/Button";
import Input = require("Everlaw/Input");
import { Arr, Is } from "core";
import Message = require("Everlaw/Messaging/Message");
import { ObjectList } from "Everlaw/UI/ObjectList";
import Perm = require("Everlaw/PermissionStrings");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import { Recipient } from "Everlaw/Recipient";
import Rest = require("Everlaw/Rest");
import Security = require("Everlaw/Security");
import SearchResult = require("Everlaw/SearchResult");
import ShareableObject = require("Everlaw/Sharing/ShareableObject");
import ShareableObjectPermTable = require("Everlaw/Sharing/ShareableObjectPermTable");
import { Str } from "core";
import TextEditor = require("Everlaw/UI/TextEditor");
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");
import Validated = require("Everlaw/UI/Validated");
import Widget = require("Everlaw/UI/Widget");
import dojo_on = require("dojo/on");
import { MinimalOrganization } from "Everlaw/MinimalOrganization";
import { Failed } from "Everlaw/Rest";
import { ProjectRole } from "Everlaw/Security";
import { Group } from "Everlaw/User";

/**
 * A new message that hasn't been sent yet. This class contains the logic/model, while the Composer
 * class below contains the UI. These two classes are fairly coupled, but keeping them separate
 * helps the code stay more organized and readable.
 */
class NewMessage {
    // Recipients who should be displayed in the dropdown. This includes those who are allowed to
    // receive the message, as well as those who can't receive the message but should still be
    // displayed in a disabled row of the dropdown (e.g., recipients who can't be shared a search
    // because they can't be shared objects that the search references).
    potentialRecipients: {
        roles: Security.ProjectRolePrimitive[];
        groups: User.Group[];
        users: User[];
        organizations: MinimalOrganization[];
    };
    // The set of recipients who don't have permissions to receive the attachment. Used to display
    // possible warning messages.
    nonAttachmentRecipients: Set<Recipient> = new Set();
    attachment: Base.Object;
    attachmentClassInfo: ShareableObject.ClassInfo;
    canRemoveAttachment: boolean;
    // Pre-populated subject
    initialSubject: string;
    // Pre-populated body (either Dom.Content or html).
    initialBody: Dom.Content;
    initialBodyHTML: string;
    inReplyTo: Message;
    private initialTo: string | User | (string | User)[];
    private userFilter: (u: User) => boolean;
    private groupFilter: (g: User.Group) => boolean;
    private onSendCompleteCallback: (success: boolean, data: Message | Rest.Failed) => void;
    sendSuccessful = false;
    protected toDestroy: Util.Destroyable[] = [];

    constructor(params: NewMessage.Params) {
        this.inReplyTo = params.inReplyTo;
        this.initialTo = params.to;
        this.userFilter = params.userFilter;
        this.groupFilter = params.groupFilter;
        this.initialSubject = params.subject;
        this.initialBody = params.body;
        this.initialBodyHTML = params.bodyHTML;
        this.attachment = params.attachment;
        this.canRemoveAttachment = !!params.canRemoveAttachment;
        this.onSendCompleteCallback = params.onSendComplete;
        if (this.attachment) {
            this.attachmentClassInfo = ShareableObject.getClassInfo(this.attachment);
        }
    }

    hasInvalidAttachment(): boolean {
        return this.attachment instanceof HomepageFolder || this.attachment instanceof SearchResult;
    }

    /** Returns true if the specified recipient has permissions to receive the attachment. */
    protected canReceiveAttachment(recipient: Recipient): boolean {
        return (
            (!(recipient instanceof User.Group)
                || Arr.contains(this.potentialRecipients.groups, recipient))
            && (!(recipient instanceof User)
                || Arr.contains(this.potentialRecipients.users, recipient))
        );
    }

    /** Returns true if the user can specify recipients' permissions on the attachment. */
    canSetPermissions(): boolean {
        // Allow setting permissions on a dummy attachment
        if (!Is.defined(this.attachment.id)) {
            return true;
        }
        return (
            this.attachmentClassInfo.securityMap
            && User.me.can(
                Perm.ADMIN,
                <Base.SecuredObject>this.attachment,
                User.Override.ELEVATED_OR_ORGADMIN,
            )
        );
    }

    /** Returns true if the user has permissions to share the attachment. */
    canShareAttachment(): boolean {
        if (this.attachmentClassInfo.shareCheck) {
            return this.attachmentClassInfo.shareCheck(<Base.SecuredObject>this.attachment);
        }
        return !this.attachmentClassInfo.securityMap || this.canSetPermissions();
    }

    /** Returns true if the attachment from the previous message in the thread was deleted. */
    isAttachmentDeleted(): boolean {
        return !this.attachment && !!this.inReplyTo && !!this.inReplyTo.attachmentClass;
    }

    /** Build the list of potential recipients, applying any specified permission filters. */
    buildPotentialRecipients(): void {
        const roles = Base.get(Security.ProjectRolePrimitive);

        const groups = Base.get(User.Group).filter((g) => {
            return !g.deleted && (!this.groupFilter || this.groupFilter(g));
        });

        const possibleUsers = Base.get(User).filter((u) => u.isEverlawUser() || u.isActiveUser());
        const users = this.userFilter
            ? possibleUsers.filter((u) => u.isEverlawUser() || this.userFilter(u))
            : possibleUsers;

        const organizations = Base.get(MinimalOrganization).filter((org) => {
            return org.isSharable(possibleUsers);
        });

        this.potentialRecipients = { roles, users, groups, organizations };
    }

    /** Get the list of initial recipients to populate the select, filtering out deleted groups. */
    getInitialRecipients(): Recipient[] {
        return Arr.wrap(this.initialTo)
            .map((r) => (Is.string(r) ? ShareableObject.toRecipientEbo(r) : r))
            .filter((obj) => Is.object(obj) && (!(obj instanceof Group) || !obj.deleted));
    }

    /** Callback for when the recipient list changes. */
    onRecipientChange(recipient: Recipient, added: boolean): void {
        // Update our set of recipients who can't receive the attachment.
        if (this.attachment && !this.canReceiveAttachment(recipient)) {
            if (added) {
                this.nonAttachmentRecipients.add(recipient);
            } else {
                this.nonAttachmentRecipients.delete(recipient);
            }
        }
    }

    /** Remove the attachment from the message. */
    removeAttachment(): void {
        this.attachment = null;
        this.attachmentClassInfo = null;
        this.userFilter = null;
        this.groupFilter = null;
        this.nonAttachmentRecipients = new Set();
        ga_event("Message", "Remove Attachment");
    }

    /**
     * Send the message. Can be overridden by subclasses that need to do any additional parameter
     * massaging before calling _doSend().
     */
    send(params: NewMessage.SendParams): void {
        this._doSend(params);
    }

    /** Send the request to the backend and call onSendComplete() when done. */
    protected _doSend(params: NewMessage.SendParams): void {
        Rest.post("messages/send.rest", {
            replyTo: this.inReplyTo && this.inReplyTo.id,
            recipients: params.recipients,
            text: params.text,
            attachmentClass: this.attachment && this.attachment.className,
            attachmentId: this.attachment && this.attachment.id,
            attachmentPerm: params.attachmentPerm,
            subject: params.subject,
        }).then(
            (data) => this._handleDataSuccess(data),
            (reason: Rest.Failed) => {
                this.onSendComplete(false, reason);
            },
        );
        ga_event(
            "Message",
            "Send",
            this.attachment ? this.attachment.className : "",
            params.recipients.length,
        );
    }

    _handleDataSuccess(data: unknown) {
        this.sendSuccessful = true;
        Message.messageSent.publish({});
        if (this.attachmentClassInfo && this.attachmentClassInfo.securityMap) {
            ShareableObject.securityChangeChannel.publish(this.attachment);
        }
        this.onSendComplete(true, <Message | Failed>data);
    }

    /**
     * Callback for when the response is received from the server. if success is false, data will be
     * a Rest.Failed.
     */
    onSendComplete(success: boolean, data: Message | Rest.Failed): void {
        this.onSendCompleteCallback && this.onSendCompleteCallback(success, data);
    }

    cancel(): void {
        ga_event("Message", "Cancel");
    }

    destroy(): void {
        Util.destroy(this.toDestroy);
    }
}

module NewMessage {
    /** Constructor params for NewMessage. */
    export interface Params {
        // If the message is a reply, this is who is being replied to.
        inReplyTo?: Message;
        // The initial set of recipients to be populated by the composer. Each recipient could be:
        //   - a User EBO
        //   - a User Id
        //   - a string representing a user group ("GROUP_<group_id>")
        //   - a string representing an organization ("ORG_<org_id>")
        to?: string | User | (string | User)[];
        // Filters used to limit users/groups that can receive the message due to attachment permissions.
        userFilter?: (u: User) => boolean;
        groupFilter?: (g: User.Group) => boolean;
        // Pre-populated subject content.
        subject?: string;
        // Pre-populated message content. Can be either Dom.Content or a raw html string. Only one of
        // these should be specified.
        body?: Dom.Content;
        bodyHTML?: string;
        // Optional attachment.
        attachment?: Base.Object;
        // If true, the user can remove the attachment from the message.
        canRemoveAttachment?: boolean;
        // Callback triggered when the send has completed.
        onSendComplete?: (success: boolean, data: Message | Rest.Failed) => void;
    }

    /** Params used by the message's send() method. */
    export interface SendParams {
        recipients: string[];
        subject?: string;
        text: string;
        attachmentPerm?: string;
        shareSubset?: boolean;
        subsetName?: string;
    }

    /** Constructor params for the Composer class. */
    export interface ComposerParams {
        // If specified, the composer will place its content in the node.
        // If not specified, the composer will place its content in a query dialog and show it.
        parentNode?: HTMLElement;
        // If the dialog title should be something other than "Send message". Only used when parentNode
        // is not specified (i.e., for dialogs).
        dialogTitle?: string;
        // If the submit button text should be something other than "Send". Only used when parentNode
        // is not specified (i.e., for dialogs).
        submitText?: string;
        // Security info for the attachment, if applicable.
        attachmentSecurity?: ShareableObject.ObjectSecurity;
        // Callback for when the request is made to the backend.
        onSubmit?: () => void;
        // Callback for when the user cancels the message.
        onCancel?: () => void;
        // If the subject line should be hidden in the composer.
        hideSubjectLine?: boolean;
        // If the list of recipients should be labeled something other than "To".
        recipientLabel?: string;
        // Default height if not specified is 150px if there is an attachment, 250px if not.
        textEditorHeight?: number;
        // Callback when the submit button should be enabled/disabled.
        disableSubmit?: (disable: boolean) => void;
        // Whether to destroy the composer when the dialog closes.
        destroyOnClose?: boolean;
    }

    /**
     * The UI portion of a new message that hasn't been sent yet.
     */
    export class Composer {
        content: HTMLElement;
        // The dialog is only used if parentNode is not specified.
        dialog: QueryDialog;
        editor: TextEditor;
        protected recipientsContainer: HTMLElement;
        protected recipientsWidget: ObjectList<Recipient>;
        private attachmentSecurity: ShareableObject.ObjectSecurity;
        protected editorContainer: HTMLElement;
        protected attachmentContainer: HTMLElement;
        protected attachmentPermsContainer: HTMLElement;
        protected attachmentPermsInitialRow: HTMLElement;
        protected attachmentWarningContainer: HTMLElement;
        protected attachmentWarningMsgDiv: HTMLElement;
        protected nonAttachmentRecipientsDiv: HTMLElement;
        protected onSubmitCallback: () => void;
        private onCancelCallback: () => void;
        protected newPermissions: ShareableObject.PermSelector;
        private disableButtonTimer: number;
        protected subjectContainer: HTMLElement;
        protected subjectWidget: Validated.Text;
        private subjectEditorNode: HTMLDivElement;
        protected subjectEditorHeader: HTMLDivElement;
        protected messageAttachmentName: HTMLDivElement;
        private subjectEditorIcon: IconButton;
        private cancelSubjectEdit: HTMLSpanElement;
        private disableSubmitCallback: (disable: boolean) => void;
        protected toDestroy: Util.Destroyable[] = [];
        static TEXT_EDITOR_DEFAULT_HEIGHT_WITH_ATTACHMENT = 150;
        static TEXT_EDITOR_DEFAULT_HEIGHT_WITHOUT_ATTACHMENT = 250;
        private static MAX_SUBJECT_CHARS = 150;
        constructor(
            public message: NewMessage,
            params: NewMessage.ComposerParams = {},
        ) {
            this.content = Dom.div({
                class:
                    "new-message-composer"
                    + (params.parentNode ? "" : " new-message-composer-dialog"),
            });
            this.attachmentSecurity = params.attachmentSecurity;
            this.onSubmitCallback = params.onSubmit;
            this.onCancelCallback = params.onCancel;
            this.disableSubmitCallback = params.disableSubmit;
            this.buildRecipientsWidget(params.recipientLabel);
            !params.hideSubjectLine && this.buildSubjectWidget();
            this.buildEditor(params.parentNode, params.textEditorHeight);
            this.buildAttachmentContainer();
            this.buildAttachmentPermsContainer();
            this.buildAttachmentWarningContainer();

            Dom.setContent(this.content, this.buildDom(params));
            this._initAttachment();
            this.addRecipients(this.message.getInitialRecipients());

            if (params.parentNode) {
                Dom.place(this.content, params.parentNode);
            } else {
                this.dialog = new QueryDialog(this.getDialogParams(params));
                this.dialog._toDestroy.push(this);
                this.onShow();
            }
            this.checkAndDisableSubmit();
            this.toDestroy.push(
                ShareableObject.securityChangeChannel.subscribe((obj) => {
                    // If the security of the object has changed (and the change didn't originate from us),
                    // we need to re-fetch the security of the object from the server and rebuild the
                    // recipients widget to update the current state.
                    if (obj === this.message.attachment && !this.message.sendSuccessful) {
                        ShareableObject.getSecurity(this.message.attachment).then(
                            (data: ShareableObject.ObjectSecurity) => {
                                this.attachmentSecurity = data;
                                this.buildRecipientsWidget();
                            },
                        );
                    }
                }),
            );
        }

        /** Can be overridden to add additional dialog parameters. */
        protected getDialogParams(params: NewMessage.ComposerParams): QueryDialog.Params {
            return {
                title: params.dialogTitle || "Send message",
                prompt: "",
                body: this.content,
                autofocus: false,
                destroyOnClose: Is.boolean(params.destroyOnClose) ? params.destroyOnClose : true,
                submitText: params.submitText || "Send",
                cancelText: "Cancel",
                onSubmit: () => this.onSubmit(),
                onCancel: () => {
                    this.onCancel();
                    return true;
                },
            };
        }

        /** Can be overridden if there are composers that need to look different. */
        protected buildDom(params: ComposerParams): Dom.Content {
            return [
                this.recipientsContainer,
                this.attachmentPermsContainer,
                this.subjectContainer,
                this.editorContainer,
                this.attachmentContainer,
                this.attachmentWarningContainer,
            ];
        }

        /** (Re)build the recipients widget, destroying the old one if needed. */
        protected buildRecipientsWidget(recipientLabel = "To"): void {
            if (!this.recipientsContainer) {
                this.recipientsContainer = Dom.div(
                    {
                        class: "message-recipient-container message-header-container",
                    },
                    Dom.div({ class: "message-header-container__label" }, recipientLabel),
                );
            }
            let preloadedRecipients: Recipient[] = [];
            if (this.recipientsWidget) {
                preloadedRecipients = this.recipientsWidget.getValues();
                // Remove existing recipients before deleting to trigger the callbacks.
                preloadedRecipients.forEach((r) => this.recipientsWidget.remove(r));
                const node = Dom.node(this.recipientsWidget);
                Util.destroy(this.recipientsWidget);
                Dom.destroy(node);
            }
            const params = this.getRecipientsWidgetParams();
            this.recipientsWidget = new ObjectList<Recipient>(params);
            this.addRecipients(preloadedRecipients);
            Dom.place(this.recipientsWidget, this.recipientsContainer);
        }

        /** Build the parameters passed to the recipients widget constructor. */
        protected getRecipientsWidgetParams(): BaseObjectList.Params<Recipient> {
            this.message.buildPotentialRecipients();
            const params: BaseObjectList.Params<Recipient> = {
                elements: [
                    this.message.potentialRecipients.roles,
                    this.message.potentialRecipients.groups,
                    this.message.potentialRecipients.users,
                    this.message.potentialRecipients.organizations,
                ],
                nameMap: { MinimalOrganization: "Organizations" },
                placeholder: "Choose a recipient...",
                textBoxAriaLabel: "Send message, choose a recipient",
                // 370px keeps the menu just within the surrounding dialog.
                menuMaxHeight: "370px",
                onChange: (recipient, added) => this.onRecipientChange(recipient, added),
                getDisplayValues: (recipient: Recipient) => {
                    const abbrevString =
                        recipient instanceof User ? recipient.labelAbbrevsDisplay() : "";
                    return [recipient.display(), abbrevString];
                },
                getRowBodyTooltips: (recipient: Recipient) => {
                    return recipient instanceof User ? recipient.labelsDisplay() : null;
                },
                matchStyles: () => [
                    "",
                    "margin-left: 4px; font-family: Open Sans, Arial, sans-serif; font-weight: 600; "
                        + "font-size: 12px; color: #9d9d9d;",
                ],
                focusStyling: "focus-text-style",
            };
            return Object.assign(params, this.getRecipientsWidgetRowParams());
        }

        protected setSubjectMode(editing: boolean) {
            Dom.show([this.subjectWidget, this.cancelSubjectEdit], editing);
            Dom.hide(this.subjectEditorNode, editing);
            if (editing) {
                this.subjectWidget.focus();
                this.subjectWidget.input.setSelected();
            } else {
                this.subjectEditorHeader = Dom.div(
                    { class: "subject-editor ellipsed", id: "subject-editor ellipsed" },
                    this.subjectWidget.getValue().slice(0, Composer.MAX_SUBJECT_CHARS),
                );
                Dom.setContent(this.subjectEditorNode, [
                    this.subjectEditorHeader,
                    this.subjectEditorIcon.node,
                ]);
            }
        }

        protected buildSubjectWidget(): void {
            if (!this.subjectContainer) {
                this.subjectContainer = Dom.div(
                    {
                        class: "message-header-container subject",
                    },
                    Dom.span({ class: "message-header-container__label" }, "Subject line"),
                );
            }
            this.toDestroy.push(
                (this.subjectWidget = new Validated.Text({
                    name: "subject line",
                    placeholderMessage: "Enter subject line",
                    textBoxAriaLabel: "Share deposition, enter subject line",
                    validator: (val: string) => val.length <= Composer.MAX_SUBJECT_CHARS,
                    max: Composer.MAX_SUBJECT_CHARS,
                    invalidMessage:
                        "You have exceeded the maximum character limit of "
                        + Composer.MAX_SUBJECT_CHARS,
                    value:
                        this.message.initialSubject
                        && this.message.initialSubject.substring(0, Composer.MAX_SUBJECT_CHARS),
                    onChange: () => this.checkAndDisableSubmit(),
                })),
            );
            this.toDestroy.push(
                new Widget.DijitFocusContainer({
                    domNode: this.subjectContainer,
                    onBlur: () => {
                        if (this.message.initialSubject) {
                            this.setSubjectMode(false);
                        }
                    },
                }),
            );
            Dom.addContent(this.subjectContainer, this.subjectWidget.getNode());
            Dom.style(this.subjectWidget.getNode(), { width: "100%" });
            if (this.message.initialSubject) {
                this.toDestroy.push(
                    (this.subjectEditorIcon = new IconButton({
                        iconClass: "pencil-20",
                        tooltip: "Edit subject",
                        onClick: () => {
                            this.setSubjectMode(true);
                        },
                    })),
                );
                this.subjectEditorNode = Dom.div({ class: "subject-editor-node" });
                this.cancelSubjectEdit = Dom.span(
                    { class: "text-action action cancel-subject-edit hidden" },
                    "Cancel",
                );
                this.toDestroy.push(
                    dojo_on(this.cancelSubjectEdit, Input.tap, () => {
                        this.subjectWidget.setValue(this.message.initialSubject);
                        this.setSubjectMode(false);
                    }),
                );
                Dom.place([this.cancelSubjectEdit, this.subjectEditorNode], this.subjectContainer);
                this.setSubjectMode(false);
            }
        }

        /**
         * Disable the submit button on the dialog (if it exists) if one of the following is true:
         * - there are no recipients
         * - the message requires text and the text editor is empty
         * - the subject line is invalid (longer than 150 chars)
         */
        checkAndDisableSubmit(): void {
            this.disableSubmit(this.invalid());
        }

        protected invalid(): boolean {
            return (
                this.recipientsWidget.getValues().length === 0
                || (this.requiresText() && !this.editor.getValue())
                || (this.subjectWidget
                    && this.subjectWidget.getValue()
                    && !this.subjectWidget.isValid())
            );
        }

        protected disableSubmit(shouldDisable: boolean): void {
            this.dialog && this.dialog.disableSubmit(shouldDisable);
            this.disableSubmitCallback && this.disableSubmitCallback(shouldDisable);
        }

        /**
         * Specify formatting of the rows for the recipients widget selector. Can be overridden by
         * subclasses that have their own requirements here (like the search message composer).
         */
        protected getRecipientsWidgetRowParams(): {
            prepRowElement?: (recipient: Recipient) => BaseSelect.Row;
            icon?: (recipient: Recipient) => string;
            iconConfig?: (recipient: Recipient, icon: Icon) => void;
        } {
            return {
                prepRowElement: (recipient: Recipient) => {
                    const row: BaseSelect.Row = {
                        node: Dom.div(
                            { class: "table-row action recipients-widget-row" },
                            Dom.div(
                                { class: "recipients-widget-row__recip" },
                                Dom.div(
                                    { class: "recipients-widget-row__recip-display" },
                                    recipient.display(),
                                ),
                            ),
                        ),
                        onDestroy: [],
                    };
                    // If the potential recipient already has perms on the attachment, display them
                    // (unless it's the PROJECT_ADMIN role, in which case we can suppress since
                    // project admins always have full permissions on all objects).
                    const sid = recipient.sid();
                    if (this.attachmentSecurity && sid !== ProjectRole.PROJECT_ADMIN) {
                        const securityMap = this.message.attachmentClassInfo.securityMap;

                        let index = -1;
                        if (this.attachmentSecurity.sharedSecurity) {
                            const sharedPerms = this.attachmentSecurity.sharedSecurity[sid];
                            index = ShareableObject.getSecurityMapIndex(securityMap, sharedPerms);
                        }
                        if (this.attachmentSecurity.sharedFolderSecurity) {
                            const sharedFolderPerms =
                                this.attachmentSecurity.sharedFolderSecurity[sid];
                            index = Math.max(
                                index,
                                ShareableObject.getSecurityMapIndex(securityMap, sharedFolderPerms),
                            );
                        }

                        if (index >= 0) {
                            Dom.place(
                                Dom.div(
                                    { class: "recipients-widget-row__perms" },
                                    securityMap.elems[index].display(),
                                ),
                                row.node,
                            );
                        }
                    }
                    return row;
                },
            };
        }

        private addRecipients(recipients: Recipient[]) {
            recipients.forEach((recipient) => this.recipientsWidget.add(recipient));
        }

        /** Build the list of recipient SIDs based on the currently selected values in the widget. */
        protected getRecipientSids(): string[] {
            return this.recipientsWidget.getValues().map((r) => r.sid());
        }

        /** Do any necessary handling for a recipient addition/removal. */
        protected onRecipientChange(recipient: Recipient, added: boolean): void {
            const origSize = this.message.nonAttachmentRecipients.size;
            this.message.onRecipientChange(recipient, added);
            // Only listen for changes to the submit button if there is at least one recipient and
            // the message requires text
            if (this.recipientsWidget.getValues().length && this.requiresText()) {
                this.startCheckSubmitTimer();
            } else {
                this.stopCheckSubmitTimer();
            }
            if (this.message.nonAttachmentRecipients.size !== origSize) {
                if (this.message.nonAttachmentRecipients.size) {
                    Dom.setContent(
                        this.nonAttachmentRecipientsDiv,
                        this.buildNonAttachmentRecipientsMsg(),
                    );
                    Dom.show(this.nonAttachmentRecipientsDiv);
                } else {
                    Dom.hide(this.nonAttachmentRecipientsDiv);
                }
            }
            this.checkAndDisableSubmit();
        }

        /** Build the message to show when one or more recipients can't be shared the attachment. */
        private buildNonAttachmentRecipientsMsg() {
            const recips = this.message.nonAttachmentRecipients;
            const num = recips.size;
            const array = Array.from(recips)
                .sort()
                .map((recip) => recip.display());
            return [
                "Note: ",
                Dom.span({ class: "non-attach-recips" }, Str.arrayToStringList(array)),
                " ",
                this.nonAttachmentRecipientsPermissionConflicts(),
                " ",
                Str.pluralForm("This recipient", num, "These recipients"),
                " will still receive the text of the message.",
            ];
        }

        /** Build the custom reason for users who can't be shared the attachment. */
        protected nonAttachmentRecipientsPermissionConflicts(): Dom.Content {
            return (
                " no longer "
                + Str.pluralForm("has", this.message.nonAttachmentRecipients.size, "have")
                + " Receive "
                + this.message.attachmentClassInfo.projectPermissionName
                + " permissions."
            );
        }

        /** Build the text editor. */
        protected buildEditor(parentNode: HTMLElement, editorHeight: number): void {
            const editorDivId = "message-text" + (parentNode ? "-" + parentNode.id : "");
            this.editorContainer = Dom.div(
                { class: "note-content message-text-container" },
                Dom.div({ id: editorDivId }),
            );
            if (!parentNode && !editorHeight) {
                editorHeight = this.message.attachment
                    ? Composer.TEXT_EDITOR_DEFAULT_HEIGHT_WITH_ATTACHMENT
                    : Composer.TEXT_EDITOR_DEFAULT_HEIGHT_WITHOUT_ATTACHMENT;
            }
            // The TinyMCE formatting toolbar + border = 42px, so we subtract that.
            const minHeight = editorHeight ? editorHeight - 42 : null;
            this.editor = new TextEditor(this.getTextEditorParams(editorDivId, minHeight));
            this.toDestroy.push(this.editor);
            if (!parentNode) {
                Dom.style(this.editorContainer, { height: editorHeight + "px", minWidth: "578px" });
            }
        }

        /** Build the params to pass to the text editor constructor. */
        protected getTextEditorParams(divId: string, height: number): TextEditor.Params {
            return {
                divId,
                showOnInit: true,
                focusOnShow: this.message.getInitialRecipients().length > 0,
                extraButtons: true,
                autoResize: false,
                minHeight: height,
                initialContent:
                    this.message.initialBodyHTML
                    || Dom.div(this.message.initialBody || "").innerHTML,
                onChange: () => this.checkAndDisableSubmit(),
                ariaLabel: "Type message here",
            };
        }

        /**
         * Returns true if the message requires text. This is only false in the case where we are
         * sharing an attacment that can't be deleted.
         */
        private requiresText() {
            return !this.message.attachment || this.message.canRemoveAttachment;
        }

        /** Create the container that the attachment sits in. */
        protected buildAttachmentContainer(): void {
            this.attachmentContainer = Dom.div({ class: "attachment-container hidden" });
        }

        /**
         * Do some preliminary error checking on the attachment, then show the attachment and
         * call initAttachment().
         */
        protected _initAttachment(): void {
            if (this.message.isAttachmentDeleted()) {
                const attachmentClass = this.message.inReplyTo.attachmentClass;
                const classDisplay = ShareableObject.getClassDisplayName(attachmentClass);
                this.showAttachmentError(`The attached ${classDisplay} no longer exists
                and has been removed from this message`);
            }
            if (!this.message.attachment) {
                return;
            }
            if (this.message.hasInvalidAttachment()) {
                // This object type is not allowed to be shared through this dialog.
                let errMsg: Dom.Content;
                if (
                    this.message.attachmentClassInfo
                    && this.message.attachmentClassInfo.invalidAttachmentMsg
                ) {
                    errMsg = this.message.attachmentClassInfo.invalidAttachmentMsg;
                } else {
                    Bugsnag.notify(
                        Error(
                            "Created message composer with invalid attachment: "
                                + this.message.attachment.className,
                        ),
                    );
                    errMsg =
                        "There was an error including the attachment. It will not be sent with the message.";
                }
                this.showAttachmentError(errMsg);
            } else if (!this.message.canShareAttachment()) {
                this.showAttachmentError(this.getCantShareAttachmentError());
            } else {
                this.buildAndShowAttachment();
                this.initAttachmentPerms();
            }
        }

        protected getCantShareAttachmentError(): Dom.Content {
            const classDisplay = ShareableObject.getClassDisplayName(this.message.attachment);
            return `You do not have permissions to share the attached ${classDisplay}.
            It has been removed from this message.`;
        }

        protected buildAndShowAttachment(): void {
            this.messageAttachmentName = Dom.div(
                { class: "message-attachment__name" },
                this.message.attachment.display(),
            );
            const attachment = Dom.div(
                { class: "message-attachment" },
                Dom.div({ class: "message-attachment__icon" }, this.getAttachmentIcon()),
                Dom.div(
                    { class: "message-attachment__name-container" },
                    this.messageAttachmentName,
                ),
            );

            if (this.allowAttachmentPermsEdit()) {
                const editNode = ActionNode.textAction("Edit existing permissions", () => {
                    ShareableObjectPermTable.showObjectDialog(
                        this.message.attachment,
                        this.attachmentSecurity,
                    );
                });
                Dom.addClass(editNode, "message-attachment__edit");
                Dom.place(editNode, attachment);
                this.toDestroy.push(editNode);
            }

            if (this.message.canRemoveAttachment) {
                const deleteNode = Dom.create(
                    "div",
                    { class: "message-attachment__delete" },
                    attachment,
                );
                this.toDestroy.push(
                    new IconButton({
                        iconClass: "x-20",
                        tooltip: "Remove attachment",
                        onClick: () => this.removeAttachment(),
                        parent: deleteNode,
                    }),
                );
            }
            Dom.setContent(this.attachmentContainer, [
                attachment,
                Dom.div({ class: "message-attachment-padding" }),
            ]);
            Dom.show(this.attachmentContainer, Is.defined(this.message.attachment?.id));
        }

        /** Create the icon associated with the attachment and return its node. */
        protected getAttachmentIcon(): HTMLElement {
            const attachmentIcon = new Icon(this.message.attachmentClassInfo.icon, {
                tooltip: this.message.attachmentClassInfo.displayName,
            });
            this.toDestroy.push(attachmentIcon);
            return Dom.node(attachmentIcon);
        }

        /** Can be overridden by subclasses to suppress editing or change the logic. */
        protected allowAttachmentPermsEdit(): boolean {
            return this.message.canSetPermissions();
        }

        /** Remove the attachment from the message. */
        protected removeAttachment(): void {
            this.message.removeAttachment();
            Dom.empty(this.attachmentPermsContainer);
            Dom.hide(this.attachmentPermsContainer);
            Dom.empty(this.attachmentContainer);
            Dom.hide(this.attachmentContainer);
            Dom.empty(this.attachmentWarningContainer);
            Dom.hide(this.attachmentWarningContainer);
            this.attachmentSecurity = null;
            // Rebuild the recipients widget as the list of potential recipients and their row displays
            // may have changed.
            this.buildRecipientsWidget();
        }

        /** Show an error associated with the attachment. */
        protected showAttachmentError(content: Dom.Content): void {
            this.removeAttachment();
            Dom.setContent(this.attachmentContainer, Icon.callout("alert-triangle-20", content));
            Dom.show(this.attachmentContainer);
        }

        protected buildAttachmentPermsContainer(): void {
            this.attachmentPermsContainer = Dom.div({ class: "attachment-perms-container hidden" });
        }

        /** Can be overridden by subclasses to handle additional attachment permissions properly. */
        protected initAttachmentPerms(): void {
            if (this.message.canSetPermissions()) {
                Dom.show(this.attachmentPermsContainer);
                const initialRowInfo = this.createAttachmentPermsRow(
                    this.getAttachmentPermsInitialRowHeading(),
                    this.message.attachmentClassInfo.securityMap,
                );
                this.attachmentPermsInitialRow = initialRowInfo.row;
                this.newPermissions = initialRowInfo.selector;
            }
            if (this.shouldShowAttachmentWarning()) {
                this.showAttachmentWarning(this.buildAttachmentWarningMessage());
            }
        }

        /** Can be overridden by subclasses to qualify the permission type being set. */
        protected getAttachmentPermsInitialRowHeading(): string {
            return "Permission";
        }

        protected createAttachmentPermsRow(
            heading: string,
            securityMap: ShareableObject.SecurityMap,
            icon?: Icon,
        ): { row: HTMLElement; selector: ShareableObject.PermSelector } {
            const perms = Dom.div({ class: "attachment-perms-row__perms" });
            const row = Dom.div(
                { class: "message-header-container attachment-perms-row" },
                Dom.div({ class: "message-header-container__label" }, heading),
                icon ? Dom.node(icon) : null,
                perms,
            );
            Dom.place(row, this.attachmentPermsContainer);
            let selector: ShareableObject.PermSelector;
            if (securityMap.elems.length === 1) {
                Dom.place(Dom.div(securityMap.elems[0].display()), perms);
                Dom.addClass(perms, "single-perm");
            } else {
                selector = new ShareableObject.PermSelector({
                    parent: perms,
                    securityMap: securityMap,
                    default: securityMap.elems[0],
                });
                this.toDestroy.push(selector);
            }
            return { row, selector };
        }

        /** Build the container for the attachment warning. */
        private buildAttachmentWarningContainer() {
            this.attachmentWarningContainer = Dom.div(
                { class: "message-warning hidden" },
                Icon.callout("alert-triangle-20", [
                    (this.attachmentWarningMsgDiv = Dom.div({ class: "warning-text receive" })),
                    (this.nonAttachmentRecipientsDiv = Dom.div({
                        class: "warning-text non-attach-recips-warning hidden",
                    })),
                ]),
            );
        }

        /** Can be overridden by subclasses. */
        protected shouldShowAttachmentWarning(): boolean {
            return !!this.message.attachmentClassInfo.projectPermissionName;
        }

        /** Show a warning associated with the attachment. */
        protected showAttachmentWarning(warning: Dom.Content): void {
            Dom.setContent(this.attachmentWarningMsgDiv, warning);
            Dom.show(this.attachmentWarningContainer);
        }

        protected buildAttachmentWarningMessage(): Dom.Content {
            const permName = this.message.attachmentClassInfo.projectPermissionName;
            const readRole = Base.get(Security.ProjectRolePrimitive, ProjectRole.PROJECT_READ).name;
            return `Only groups and users with Receive ${permName} permissions are shown in the
            dropdown. If you select "${readRole}", or an Organization, only users with Receive
            ${permName} permissions will receive the message.`;
        }

        /** Should be called after the composer has been placed and is visible. */
        onShow(): void {
            this.dialog.show();
            this.dialog._dialog.setAutofocus(false);
            this.editor.edit();
            // If there were no pre-populated recipients, focus the recipients widget. (Otherwise, the
            // text editor will remain focused for typing.)
            if (!this.recipientsWidget.getValues().length) {
                this.recipientsWidget.focus();
            }
        }

        /**
         * Called when the user submits the message for sending. Return true if the send was successful,
         * false otherwise (i.e., the composer is in a bad state for sending).
         */
        onSubmit(): boolean {
            if (!this.recipientsWidget.getValues().length) {
                Dialog.ok(
                    "No recipients",
                    "Please specify at least one recipient for the message.",
                );
                return false;
            }
            const text = this.editor.getValue();
            if (!text && !this.message.attachment) {
                Dialog.ok("No text", "Please enter text for the message.");
                return false;
            }
            this.send();
            this.onSubmitCallback && this.onSubmitCallback();
            return true;
        }

        /** Send the message. Can be overridden by subclasses that need to call something different. */
        send(): void {
            this.message.send(this.getSendParams());
        }

        /** Build and return the params passed to message/send.rest. */
        protected getSendParams(): SendParams {
            const params = {
                recipients: this.getRecipientSids(),
                subject:
                    (this.subjectWidget && this.subjectWidget.getValue())
                    || this.getDefaultSubjectLine(),
                text: this.editor.getValue(),
            };
            this.setExtraSendParams(params);
            return params;
        }

        protected getDefaultSubjectLine(): string {
            return (
                this.message.initialSubject
                || "Message with "
                    + array.map(this.getRecipientSids(), ShareableObject.displaySid).join(", ")
            );
        }

        /** Set any extra send params. Can be overridden by subclasses. */
        protected setExtraSendParams(params: SendParams): void {
            params.attachmentPerm = this.getAttachmentPerm();
        }

        protected getAttachmentPerm(): string {
            return (
                this.message.attachment && this.newPermissions && this.newPermissions.getSelected()
            );
        }

        /** Called when the user cancels the message. */
        onCancel(): void {
            this.message.cancel();
            this.onCancelCallback && this.onCancelCallback();
        }

        destroy(): void {
            Util.destroy(this.recipientsWidget);
            Util.destroy(this.toDestroy);
            this.stopCheckSubmitTimer();
            this.dialog = null;
            this.editor = null;
        }

        /**
         * This is a bit messy, but the editor's onChange callback doesn't actually fire for every
         * content change, meaning it would be possible to have a disabled submit button when it should
         * be enabled (and vice-versa) without this timer.
         */
        private startCheckSubmitTimer() {
            const CHECK_SUBMIT_TIMER = 250; // ms
            if (!this.disableButtonTimer) {
                this.disableButtonTimer = setInterval(
                    () => this.checkAndDisableSubmit(),
                    CHECK_SUBMIT_TIMER,
                );
            }
        }

        private stopCheckSubmitTimer() {
            if (this.disableButtonTimer) {
                clearInterval(this.disableButtonTimer);
                this.disableButtonTimer = null;
            }
        }

        /**
         * Sets given sids if they are available in potential recipients.
         * If any of them is not found, none would be set.
         * @param sidsToSet list of sid strings to set
         */
        setRecipients(sidsToSet: string[]): void {
            const availableRecipients = [
                ...this.message.potentialRecipients.roles,
                ...this.message.potentialRecipients.groups,
                ...this.message.potentialRecipients.users,
                ...this.message.potentialRecipients.organizations,
            ];
            const availableRecipientBySid = new Map(
                availableRecipients.map((recipient) => [recipient.sid(), recipient]),
            );
            const unavailable = sidsToSet.filter((sid) => !availableRecipientBySid.has(sid));
            if (unavailable.length > 0) {
                return;
            }
            sidsToSet.forEach((sid) =>
                this.recipientsWidget.selector.select(availableRecipientBySid.get(sid)),
            );
        }

        /**
         * Sets give permission id if it is available in this attachment class.
         * If it is not found, none would be set.
         * @param permIdToSet permission id to set
         */
        setsPermission(permIdToSet: string): void {
            const found = this.message.attachmentClassInfo.securityMap.elems.findIndex(
                (perm) => perm.id === permIdToSet,
            );
            if (found < 0) {
                return;
            }
            this.newPermissions.setSelected(found);
        }
    }

    /**
     * Create a NewMessage and a NewMessage.Composer for it. In general, you should be using
     * MessageComposer.compose() rather than calling this directly. Returns a promise that resolves
     * when the composer is constructed (for cases where we need to first retrieve some information
     * asynchronously from the server).
     */
    export function compose(
        messageParams: NewMessage.Params = {},
        composerParams: NewMessage.ComposerParams = {},
        ComposerType: typeof Composer = Composer,
    ): Promise<NewMessage.Composer> {
        const createComposer = (
            messageParams: NewMessage.Params,
            composerParams: NewMessage.ComposerParams,
        ) => {
            const message = new NewMessage(messageParams);
            return new ComposerType(message, composerParams);
        };

        const attachment = messageParams.attachment;
        if (attachment) {
            const classInfo = ShareableObject.getClassInfo(attachment.className);
            // If the attachment has a security map and the user actually has ADMIN permissions on the
            // attachment (otherwise it won't be attached when we send the message), then fetch the
            // attachment's security info.
            if (
                classInfo.securityMap
                && User.me.can(
                    Perm.ADMIN,
                    <Base.SecuredObject>attachment,
                    User.Override.ELEVATED_OR_ORGADMIN,
                )
            ) {
                return new Promise<NewMessage.Composer>((resolve, reject) => {
                    ShareableObject.getSecurity(attachment).then(
                        (data: ShareableObject.ObjectSecurity) => {
                            if (data.shareableUsers) {
                                messageParams.userFilter = (user) =>
                                    Arr.contains(data.shareableUsers, user.id);
                            }
                            if (data.shareableGroups) {
                                messageParams.groupFilter = (group) =>
                                    Arr.contains(data.shareableGroups, group.id);
                            }
                            composerParams.attachmentSecurity = data;
                            resolve(createComposer(messageParams, composerParams));
                        },
                        reject,
                    );
                });
            }
        }
        return Promise.resolve(createComposer(messageParams, composerParams));
    }
}

export = NewMessage;
